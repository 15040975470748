import Controller from '../utils/Controller';
import { decorate, computed, action, observable } from 'mobx';

class Eventi extends Controller {
  constructor(options, manager) {
    super(options, manager);
    this.fetched = false;
    this.list = [];
    this.current = null;
  }
 
  get map() {
    return this.list
      .reduce((agg, curr) => {
        agg[curr.id] = curr;
        return agg;
      }, {});
  }

  get name() {
    return 'eventi'
  }

  get priority() {
    return 1000;
  }

  async get(id, nocache = null) {
    if (!nocache && this.map[id]) {
      return this.map[id];
    } else {
      const result = await this.manager.controllers.api.get(`/eventi/${id}`);
      this.list = [...this.list, result.Item];
      return result.Item;
    }
  }

  async fetch () {
    const result = await this.manager.controllers.api.get('/eventi?inCorso=1');
    this.list = result.Items;
    this.fetched = true;
  }

  async delete(id) {
    const result = await this.manager.controllers.api.del(`/cts/${id}`);
    this.users = this.users.filter(user => user.cognitoRef !== result.Item.cognitoRef);
  }

  async create(evento) {
    const result = await this.manager.controllers.api.post('/eventi', {
      body: evento
    });
    this.list = [result.Item, ...this.list];
  }

  find(id) {
    return this.list.findIndex(evento => evento.id === id);
  }

  async reload() {
    if (this.fetched) {
      await this.fetch();
    } else {
      for (let i = 0; i < this.list.length; i++) {
        await this.get(this.list[i].id, true)
      }
    }
  }

  replace(id, valore) {
    const temp = [...this.list];
    const idx = this.find(id);
    if (idx !== -1) {
      temp[idx] = valore;
      this.list = temp;
    }
    if (this.current.id === id) {
      this.current = valore;
    }
  }
  
  async update(id, evento) {
    delete evento.id;
    const result = await this.manager.controllers.api.put(`/eventi/${id}`, {
      body: evento
    });
    this.replace(id, result.Item);
  }

  setCurrent(evento) {
    this.current = evento;
  }

}

export default decorate(Eventi, {
  list: observable,
  current: observable,
  map: computed,
  fetch: action,
  delete: action,
  update: action,
  replace: action,
  get: action
});