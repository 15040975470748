import React, { useState } from 'react';
import styled from 'styled-components';
import { Form, Input, Button, Divider, Alert, Space } from 'antd';
import { MailOutlined, KeyOutlined } from '@ant-design/icons';
import cm from '../../controllers';
import { Link, navigate } from '@reach/router';

const Titolo = styled.h1`
  color: white;
  margin-top: 10vh;
  margin-bottom: 30px;
`

const CustomForm = styled(Form)`
  .ant-input-affix-wrapper {
    color:white;
    border: 0 ;
    background: transparent;
    border-bottom: 1px solid white;
    padding-bottom: 10px;
    input { outline: 0; color:white; }
    input::-webkit-input-placeholder {
      color: white;
    }
    input {
      background: transparent
    }
    .ant-input-password-icon {
      svg {
        fill: white;
      }
    }
  }
  
`;

const CustomDivider = styled(Divider)`
  .ant-divider-inner-text {
    color: white;
  }
  &:before, &:after {
    border-top: 1px solid rgba(255,255,255,.2) !important;
  }
`;


const LoginPage = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  return <>
    <Titolo>Accedi</Titolo>
    <Space direction="vertical" style={{width: '100%'}}>
    <Alert message={error} style={{
      transition: 'opacity 300ms linear',
      opacity: error ? 1 : 0,
      marginBottom: '20px'
    }}/>
    
    <CustomForm onFinish={async (values) => {
      try {
        setError(null);
        setLoading(true);
        await cm.controllers.auth.login(values.username, values.password);
        if (
          cm.controllers.auth.user.challengeName &&
          cm.controllers.auth.user.challengeName === 'NEW_PASSWORD_REQUIRED'
        ) {
          navigate('/auth/challenge/NEW_PASSWORD_REQUIRED'); 
        } else {
          navigate('/');
        }

      } catch (e) {
        console.dir(e);
        switch (e.code) {
          case 'UserNotFoundException':
            setError('Credenziali invalide')
            break;
          case "NotAuthorizedException":
            setError('Credenziali invalide');
            break;
          default:
            setError(JSON.stringify(e.toString()));
            break;
        }
      } finally {
        setLoading(false)
      }
    }}>
      <Form.Item
        hasFeedback
        type="email"
        placeholder="Email"
        name="username"
        rules={[{required:true, message: 'Campo richiesto'}, {type: 'email', message: 'Email Invalida'}]}
      >
        <Input 
          placeholder="Email"
          type="email"
          prefix={<MailOutlined style={{
            paddingRight: '10px'
          }} />} 
        />
      </Form.Item>
      <Form.Item 
        style={{marginTop: '10px'}}
        rules={[{required:true, message: 'Campo richiesto'}]}
        name="password"
        hasFeedback
      >
        <Input.Password
          placeholder="Password"
          prefix={<KeyOutlined  style={{
            paddingRight: '10px'
          }} />} 
        />
      </Form.Item>
      <Form.Item style={{marginTop: '50px'}}>
        <Button type="primary" block htmlType="submit" loading={loading}>Invia</Button>
      </Form.Item>
    </CustomForm>

    <CustomDivider>oppure</CustomDivider>
    <Link to="/auth/request-password-reset" style={{
      display: 'block',
      textAlign: 'center'
    }}>Ho dimenticato la mia password</Link>
    
    </Space>
    
  </>
};

export default LoginPage;