import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import {Form, Input, Select, Button, Modal} from 'antd';
import {PlusCircleTwoTone, DeleteOutlined, MinusCircleTwoTone } from '@ant-design/icons';
import CustomSelect from '../../../../components/custom-select';

const ListCoinvolti = styled.ul`
  list-style: none;
  padding: 0;
  li {
    border: 1px solid #3e7acb;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 5px;
    h4 {
      capitalize: true;
    }
    &>div {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    span {
      display: inline-block;
    }
  }
`;

const getFields = (value) => {
  if (!value) return null;
  switch (value) {
    case 'concorrente':
      return <Form.Item
        rules={[{required: true, message: 'Campo necessario'}]}
        name="numeroGara"
        label="Numero di gara"
        labelCol={{lg:24}}
        wrapperCol={{lg: 24}}
        labelAlign="left"
      >
        <Input  />
      </Form.Item>
    case 'organizzazione':
      return <> 
        <Form.Item
          rules={[{required: true, message: 'Campo necessario'}]}
          name="bollo"
          label="Bollo n."
          labelCol={{lg:24}}
          wrapperCol={{lg: 24}}
          labelAlign="left"
        >
          <Input  />
        </Form.Item>

        <Form.Item
          rules={[{required: true, message: 'Campo necessario'}]}
          name="targa"
          label="Targa"
          labelCol={{lg:24}}
          wrapperCol={{lg: 24}}
          labelAlign="left"
        >
          <Input  />
        </Form.Item>
      </>
    case 'media':
      return <>
        <Form.Item
          rules={[{required: true, message: 'Campo necessario'}]}
          name="bollo"
          label="Bollo n."
          labelCol={{lg:24}}
          wrapperCol={{lg: 24}}
          labelAlign="left"
        >
          <Input  />
        </Form.Item>

        <Form.Item 
          rules={[{required: true, message: 'Campo necessario'}]}
          name="targa"
          label="Targa"
          labelCol={{lg:24}}
          wrapperCol={{lg: 24}}
          labelAlign="left"
        >
          <Input  />
        </Form.Item>
      </>
    case 'assistenza':
      return <>
        <Form.Item
          rules={[{required: true, message: 'Campo necessario'}]}
          name="bollo"
          label="Bollo n."
          labelCol={{lg:24}}
          wrapperCol={{lg: 24}}
          labelAlign="left"
        >
          <Input  />
        </Form.Item>

        <Form.Item
          rules={[{required: true, message: 'Campo necessario'}]}
          name="targa"
          label="Targa"
          labelCol={{lg:24}}
          wrapperCol={{lg: 24}}
          labelAlign="left"
        >
          <Input  />
        </Form.Item>
      </>
    case 'utente-normale':
      return <Form.Item
        rules={[{required: true, message: 'Campo necessario'}]}
        name="targa"
        label="Targa"
        labelCol={{lg:24}}
        wrapperCol={{lg: 24}}
        labelAlign="left"
      >
        <Input  />
      </Form.Item>
    
    default:
      return '';
  }
}

const ModalForm = ({visible, onFinish, onClose}) => {
  const [form] = Form.useForm();
  return <Modal 
    title="Aggiungi veicolo" 
    visible={visible} 
    onCancel={() => {
      form.resetFields();
      onClose();
    }}
    onOk={() => {
      form.submit();
    }}
  >
    <Form form={form} onFinish={(values) => {
      onFinish(values);
      form.resetFields();
    }}>
      <Form.Item
        name="tipologia"
        label="Tipologia di veicolo coinvolto"
        rules={[{required: true, message: 'Campo necessario'}]}
        hasFeedback    
      >
        <CustomSelect>
          <option value="concorrente">
            Concorrente
          </option>
          <option value="organizzazione">
            Organizzazione
          </option>
          <option value="media">
            Media
          </option>
          <option value="assistenza">
            Assistenza
          </option>
          <option value="utente-normale">
            Utente normale circolazione
          </option>
        </CustomSelect>
      </Form.Item>
      <Form.Item
        style={{margin: '0'}}
        shouldUpdate={(prev, cur) => {
          if (!prev && !!cur) return true;
          return !!cur
            ? prev['tipologia'] !== cur['tipologia']
            : false
          }
        }
      >
        {
          () => getFields(form.getFieldValue('tipologia'))
        }
      </Form.Item>
    </Form>
  </Modal>
}

const Coinvolti = ({form}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const showModal = useCallback(() => {
    setModalVisible(true);
  }, []);
  const hideModal = useCallback(() => {
    setModalVisible(false)
  });

  const values = form.getFieldValue('coinvolti');

  return <Form.List name="coinvolti"   rules={[
    {required: true, message: 'Campo obbligatorio'}
  ]}>
    {(fields, {add, remove}) => {
      return <div>
        <ListCoinvolti>
        {fields.map((fieldObj) => {
          const field = (values || [])[fieldObj.name];  
          return field ? <li key={`coinvolto-${fieldObj.key}-${field.tipologia}-${field.numeroGara || field.bollo || field.targa}`}>
            <div>
              <div>
                <h4>{field.tipologia}</h4>
                {[
                  field.numeroGara ? <span key={`coinvolto-${fieldObj.key}-${field.tipologia}-${field.numeroGara}`}>Numero di gara: <strong>{field.numeroGara}</strong></span> : null,
                  field.bollo ? <span key={`coinvolto-${fieldObj.key}-${field.tipologia}-${field.bollo}`}>Bollo n.: <strong>{field.bollo}</strong></span> : null,
                  field.targa ? <span key={`coinvolto-${fieldObj.key}-${field.tipologia}-${field.targa}`}>Targa: <strong>{field.targa}</strong></span> : null
                ]}
              </div>
              <Button type="text" onClick={() => remove(fieldObj.key)}>
                <MinusCircleTwoTone />
              </Button>
            </div>
          </li> : null
        })}
        </ListCoinvolti>
        <Button 
          block
          onClick={showModal}
        >
          <PlusCircleTwoTone /> Aggiungi veicolo
        </Button>
        <ModalForm 
          visible={modalVisible}
          onClose={hideModal}
          onFinish={(values) => {
            add(values);
            hideModal();
          }}
        />
      </div>
    }}
  </Form.List>
}

export default Coinvolti;