import { Descriptions, Drawer, Tag, Image, message, Typography, Button, Row, Col } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import withS3Src from '../../../hoc/withS3Src'

import cm from '../../../controllers';
import { AimOutlined, CheckCircleOutlined, FlagOutlined, NodeIndexOutlined } from '@ant-design/icons';
import { useCallback } from 'react';
import confirm from 'antd/lib/modal/confirm';
import { navigate } from '@reach/router';
import { SEGNALAZIONI_COLORS } from '../../../controllers/Segnalazioni';

const CoinvoltiList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  li {
    border-bottom: 1px solid #ececec;
    padding-bottom: 10px;
    padding-top: 10px;
    &:last-child {
      border-bottom-color: transparent;
    }
  }

`;

const Link = styled.a`
  display: block;
  padding: 4px 15px;
  background: #fe7d07;
  font-size: 14px;
  line-height: 1.5715;
  color: white;
  text-align: center;
  height: 32px;
  border-radius: 2px;
`;

const Foto = withS3Src(Image);

const SegnalazioneDetail = (props) => {
  const [item, setItem] = useState(null);
  const [resolving, setResolving] = useState(false);

  useEffect(() => {
    const load = async () => {
      try {
        const segn = await cm.controllers.segnalazioni.get(props.id);
        setItem(segn);
      } catch (e) {
        message.error('impossibile ottenere la segnalazione')
      }
    }
    load()
  }, [props.id])

  const resolve = useCallback(() => {
    confirm({
      title: 'Risoluzione segnalazione',
      content: 'Sei sicuro di voler risolvere questa segnalazione?',
      cancelText: 'Annulla',
      okText: 'Sì, sono sicuro',
      onOk: async () => {
        try {
          setResolving(true);
          await cm.controllers.segnalazioni.resolve(props.id);
          navigate('/')
        } catch (e) {
          message.error('Errore durante la risoluzione')
        } finally {
          setResolving(false)
        }
      }
    })
    
  }, [props.id]);
  
  return <>
    <Typography.Title level={4}>Dettagli segnalazione</Typography.Title>
  {item ?
  <Descriptions bordered style={{marginTop: '40px'}}>
      <Descriptions.Item label="Codice">
       <Tag style={{
         ...SEGNALAZIONI_COLORS[item.codice],
         border: 0
       }}> {item.codice} </Tag>
      </Descriptions.Item>
      <Descriptions.Item label="Creazione">
        {new Date(item.createdAt).toLocaleTimeString()}
      </Descriptions.Item>
                
      {item.tipo ? <Descriptions.Item label="Tipologia">{item.tipo}</Descriptions.Item> : null}
      {item.resolvedAt ? <Descriptions.Item label="Risoluzione">{new Date(item.resolvedAt).toLocaleString()}</Descriptions.Item> : null}
      <Descriptions.Item label="Vetture coinvolte">
        <CoinvoltiList>
        {item.coinvolti.map(item => <li key={item.targa || item.numeroGara}>
          <h4>{item.tipologia}</h4>
          <div>
            {item.numeroGara ? <span style={{display: 'block'}}>Numero di gara: <Tag>{item.numeroGara}</Tag></span> : null}
            {item.targa ? <span style={{display: 'block'}}>Targa: <Tag>{item.targa}</Tag></span> : null}
            {item.bollo ? <span style={{display: 'block'}}>Bollo n: <Tag>{item.bollo}</Tag></span> : null}
          </div>
        </li>)}
        </CoinvoltiList>
      </Descriptions.Item>
      {item.dinamica 
        ? <Descriptions.Item label="Dinamica">{
          Array.isArray(item.dinamica)
            ? item.dinamica.join(', ')
            : item.dinamica
          }</Descriptions.Item>
        : null}
      {item.foto 
      ? <Descriptions.Item label="Foto">{
          item.foto.length === 0
            ? 'Nessuna foto disponibile'
            : item.foto.map((item, i) => <Foto style={{marginRight: '5px'}} width={50} key={`foto-${i}`} src={item} />)
        }</Descriptions.Item>
      : null}
  </Descriptions>: null}
  <footer>
    {
      cm.controllers.segnalazioni.current && cm.controllers.segnalazioni.current.id === props.id
        ? <footer style={{marginTop: '20px'}}>
          <Row gutter={16}>
            <Col span={12}>
              <Link block type="primary" href={
                (navigator.platform.indexOf("iPhone") != -1) 
                || (navigator.platform.indexOf("iPod") != -1)
                || (navigator.platform.indexOf("iPad") != -1)
                  ? `comgooglemaps://?directionsmode=driving&daddr=${item?.coords.lat},${item?.coords.lng}`
                  : `https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${item?.coords.lat},${item?.coords.lng}`
                }
              >
                <FlagOutlined /> Naviga
              </Link>
            </Col>
            <Col span={12}>
              <Button block type="primary" style={{
                background: '#55b955',
                border: '1px solid #55b955',        
                display: item?.codice === 'rosso' && cm.controllers.teams.current.tipo < 4 
                  ? 'none'
                  : 'block'
        
              }} icon={<CheckCircleOutlined />} loading={resolving} disabled={resolving} onClick={resolve}>
                Risolvi
              </Button>
            </Col>
          </Row>
        </footer>
        : null
    }
  </footer>
  </>
};

export default SegnalazioneDetail;