import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Layout, Avatar, Space, Drawer, Typography, Menu, Tag, Badge } from 'antd';
import { observer } from 'mobx-react';
import { LoadingOutlined, LogoutOutlined, MenuOutlined, MessageOutlined } from '@ant-design/icons';

import cm, { version } from '../../controllers';
import withS3Src from '../../hoc/withS3Src';
import { reaction } from 'mobx';
import { navigate } from '@reach/router';
import { TEAM_TYPE_ARRAY } from '../../controllers/Teams';

const {Header} = Layout;

const S3Avatar = withS3Src(Avatar);

const CustomDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 0;
  }
`;

const EventTitle = observer(() => {
  return cm.controllers.eventi.current
  ? <Space>
    <S3Avatar src={cm.controllers.eventi.current.logo} />
    <span>{cm.controllers.eventi.current.titolo}</span>       
  </Space>
  : <LoadingOutlined />
}); 



const MenuHeader = observer(() => {
  const [user, setUser] = useState(null)
  useEffect(() => {
    let r;
    reaction(
      () => cm.controllers.auth.user,
      async (utente, reaction) => {
        r = reaction;
        const result = await cm.controllers.cts.get(cm.controllers.auth.user.username);
        setUser(result);
      }, 
      {fireImmediately: true}
    )
    return () => r.dispose();
  })

  return <div style={{
    background:'#FE7D07',
    padding: '20px',
    color: '#333'
  }}>
    <Typography.Title style={{marginBottom: 0, color: '#333'}} level={3}>Benvenuto, </Typography.Title>
    {user ? <>
      <Typography.Title style={{margin: 0, color: '#333'}} level={4}>{user.nome} {user.cognome}</Typography.Title> 
      <small>{user.email}</small>
      <Typography.Title style={{color: '#333', marginBottom: 0}} level={5}>
        Membro del team: 
      </Typography.Title>
      <Typography.Title style={{color: '#333', margin: 0}} level={5}>
        <Space>
          {
            cm.controllers.teams.current
              ? cm.controllers.teams.current.codice 
              : ''
          }
          <Tag>{cm.controllers.teams.current ? TEAM_TYPE_ARRAY[cm.controllers.teams.current.tipo] : null}</Tag>
        </Space>
      </Typography.Title>

      </>  
      : <LoadingOutlined />
    } 
    <div style={{marginTop: '20px'}}>
      <Button style={{
        border: 0
      }} onClick={() => {
        navigate('/auth');
        cm.controllers.auth.logout()
      }} icon={<LogoutOutlined />}>Esci</Button>
    </div>
  </div>
});

const CustomHeader = observer(() => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [v, setV] = useState(undefined);

  useEffect(() => {
    const obs = version.observe((value) => {
      console.dir(value)
      setV(value.newValue)
    }, true)
    
  }, []);

  const openMenu = useCallback(() => {
    setMenuOpen(true);
  }, []);

  const closeMenu = useCallback(() => {
    setMenuOpen(false);
  }, []);

  return <>
    <Header
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: "space-between",
        padding: '0 20px',
        position: 'relative',
        zIndex: 999
      }}
    >
      <Button  onClick={openMenu} icon={<MenuOutlined style={{fontSize: '20px'}} />} type="text" />
      <EventTitle />
      <div style={{position: 'relative'}}>
        <Button type="text" onClick={() => navigate('/chat')} icon={<MessageOutlined style={{fontSize: "20px"}} />} />
        <Badge 
          style={{pointerEvents: 'none',color: 'white', position: "absolute", right: '-5px', top: '-3px' }} 
          count={
            Array.from(cm.controllers.chat.channels.keys())
              .reduce((total, channel) => total + cm.controllers.chat.channels.get(channel).unread, 0)
          } 
        />
      </div>
    </Header>
    <CustomDrawer placement="left" width="80%" onClose={closeMenu} visible={menuOpen}>
      <MenuHeader />
      <Menu style={{marginTop: '20px', background: '#15273d'}}>
        <Menu.Item onClick={() => {
          closeMenu();
          navigate('/segnalazioni')
        }}>
          Segnalazioni in corso
        </Menu.Item>
        <Menu.Item onClick={() => {
          closeMenu();
          navigate('/segnalazioni-risolte')
        }}>
          Segnalazioni risolte
        </Menu.Item>
        <Menu.Item onClick={async () => { 
          navigator.serviceWorker.getRegistrations().then(async function(registrations) {
          for(let registration of registrations) {
            await registration.unregister()
          }
          window.location = '/';
        }).catch(function(err) {
            console.log('Service Worker registration failed: ', err);
          });
        }}>
          Ricarica app
        </Menu.Item>
      </Menu>
      <footer style={{position: 'absolute', fontSize: '0.7em', bottom: 0, width: '100%', textAlign: 'right', paddingRight: '10px'}}>
        {v}
      </footer>
    </CustomDrawer>
  </>
});

export default CustomHeader;