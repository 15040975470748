import { LoadingOutlined, SendOutlined } from '@ant-design/icons';
import { Space, Typography, Form, Button, Badge } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { reaction } from 'mobx';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import styled from 'styled-components';


import cm from '../../controllers';
import CustomSelect from '../custom-select';

const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  .ant-form-item-control {
    height: 100%;
    .ant-form-item-control-input {
      height: 100%;
      .ant-form-item-control-input-content {
        height: 100%;
        textarea {
          height: 100%;
        }
      }
    }
  }
`;

const Body = styled.div`
  padding: 10px;
  background: ${props => props.me ? '#50AA19' : '#3582DB'};
  color: white;
  border-radius: 5px;
`;

const MessageList = observer(({style}) => {
  const container = useRef();
 
  const messages = cm.controllers.chat.channels.has(cm.controllers.chat.currentChannel)
    ? cm.controllers.chat.channels.get(cm.controllers.chat.currentChannel).messages
    : [];

  
  useEffect(() => {
    if (container.current) {
      container.current.scrollTop = container.current.scrollHeight;
    }
  }, [])

  useEffect(() => {
    const r = [];
    
    reaction(
      () => cm.controllers.chat.currentChannel,
      (current, reaction) => {
        r.push(reaction);
        if (container.current) {
          container.current.scrollTop = container.current.scrollHeight;
        }
      }
    );

    reaction(
      () => cm.controllers.chat.channels.get(cm.controllers.chat.currentChannel)
        ? cm.controllers.chat.channels.get(cm.controllers.chat.currentChannel).messages.items.length
        : 0,
      (current, reaction) => {
        r.push(reaction);
        if (container.current) {
          container.current.scrollTop = container.current.scrollHeight;
        }
      }
    )
    return () => {
      r.forEach(reaction => reaction.dispose());
    }
  }, [])
  

  return <div style={{padding: '15px',...style}} ref={container}>
    {
      messages && messages.items ? messages.items.map((msgObj, idx) => {
          const message = msgObj.state;
          return message ? <div key={message.sid} style={{
            marginBottom: '10px',
            display: 'flex',
            justifyContent: message.author !== cm.controllers.chat.username
              ? 'flex-end'
              : 'flex-start',
          }}>
            <Space direction="vertical" size={2} style={{maxWidth: '60%'}} >
                {
                  (message.author !== cm.controllers.chat.username) &&
                  (idx === 0 || message.author !== messages.items[idx-1].author)
                    ? <Typography.Title
                      level={5}
                      style={{
                        textAlign: message.author !== cm.controllers.chat.username
                          ? 'right'
                          : 'left'
                      }} 
                    >{message.author}</Typography.Title> 
                    : null
                }
                <Body me={message.author === cm.controllers.chat.username}>
                {message.body} 
                </Body>
                <small
                  style={{
                    display: 'block',
                    textAlign: message.author !== cm.controllers.chat.username
                      ? 'right'
                      : 'left'
                  }} 
                >
                {
                  idx === 0 || (message.timestamp.getTime() - messages.items[idx - 1].state.timestamp.getTime() > 60000)
                    ? message.timestamp.toLocaleString()
                    : null
                  }
                </small>
            </Space>
          </div> : null
        }) : null
      }
  </div>
});

const SelectChannel = observer(({style}) => {
  const channelNames = Array.from(cm.controllers.chat.channels.keys());

  return channelNames.length === 0 
    ? <LoadingOutlined /> 
    : <CustomSelect 
      style={style || {}}
      value={cm.controllers.chat.currentChannel}
      onChange={(evt) => {
        cm.controllers.chat.currentChannel = evt;
      }}
    >
    {
       channelNames
          .map(key => {
            const title = key.toLowerCase() === cm.controllers.chat.username.toLowerCase()
              ? 'Direzione gara'
              : key

            return <option key={key} value={key}>
              {
                `${title.charAt(0).toUpperCase()}${title.substr(1)}${cm.controllers.chat.channels.get(key).unread > 0 ? ` (${cm.controllers.chat.channels.get(key).unread})`: ''}`
              }
              
            </option>
          })
    }
  </CustomSelect>
});

const Chat = () => {
  const [form] = Form.useForm();

  useEffect(() => {
    const current = cm.controllers.chat.channels.get(cm.controllers.chat.currentChannel);

    if (current) {
      current.instance.setAllMessagesConsumed()
      current.unread = 0;
    }
  }, [])

  return <Container>
    <Typography.Title style={{
      padding: '24px 24px 0 24px'
    }} level={3}>
      Chat
    </Typography.Title>
    <header style={{
      padding: ' 20px 24px',
      borderTop: '1px solid rgba(255,255,255,.2)',
      borderBottom: '1px solid rgba(255,255,255,.2)',
      width: '100%',
      display: 'flex',
      alignItems: 'center'
    }}>
     <span style={{
       minWidth: '120px'
     }}>Seleziona canale: </span><SelectChannel />
    </header>
    <MessageList style={{
      height: '100%',
      overflow: 'auto',
      padding: '10px'
    }} />
    <Form form={form} layout="inline" style={{display: 'flex', height: '83px'}} onFinish={async (values) => {
      if (values.text.length === 0) return;
      await cm.controllers.chat.sendMessage(cm.controllers.chat.currentChannel, values.text);
      form.resetFields();
    }}>
      <Form.Item name="text" style={{marginRight: 0, width: '85%'}}>
        <TextArea placeholder="Scrivi il tuo messaggio qui" />
      </Form.Item>
      <Button htmlType="submit" style={{height: '100%', width:'15%'}} type="primary" icon={<SendOutlined />}></Button>
    </Form>
  </Container>
};

export default Chat;