import {STATUSES} from './ControllerManager';

class Controller {
  
  constructor(options, manager) {
    this.STATUS = STATUSES.STOPPED;
    this.options = Object.assign({
      dev: false
    }, options);
    this.manager = manager;
  }

  get priority () {
    return 1000;
  }

  get name () {
    return 'change-this';
  }

  init() {
    if (this.options.dev) console.log('Initializing controller:', this.name);
  }

  start () {
    if (this.options.dev) console.log('Starting controller:', this.name);
  }

  stopped () {
    if (this.options.dev) console.log('Stopping controller:', this.name);
  }

  pause() {
    if (this.options.dev) console.log('Pausing controller:', this.name);
  }

}

export default Controller;