import Controller from '../utils/Controller';
import { decorate, computed, action, observable, reaction } from 'mobx';
import { message, notification } from 'antd';
import { Storage } from 'aws-amplify';

export const SEGNALAZIONI_COLORS = {
  rosso: {
    background: 'rgb(219, 58, 52)',
    color: 'white'
  },
  giallo: {
    background: 'rgb(255, 200, 87)',
    color: '#333'
  },
  blu: {
    background: 'rgb(61, 165, 217)',
    color: 'white'
  },
  bianco: {
    background: 'rgb(240, 240, 240)',
    color: '#333'
  }
}

class Segnalazioni extends Controller {
  constructor(options, manager) {
    super(options, manager);
    this.fetched = false;
    this.list = [];
    this.current = null;
  }
 
  get map() {
    return this.list
      .reduce((agg, curr) => {
        agg[curr.id] = curr;
        return agg;
      }, {});
  }

  async init() {
    super.init();
    reaction(
      () => this.manager.controllers.tappe.current,
      () => {
        this.list = [];
        this.fetched = false;
      }
    )
    this.manager.controllers.websocket.addEventListener('segnalazione_created', this.add.bind(this));
    this.manager.controllers.websocket.addEventListener('segnalazione_updated', this.replace.bind(this));
    this.manager.controllers.connection.addEventListener('reconnect', this.onReconnection.bind(this));
  }

  get risolte () { 
    return this.list
      .filter(segnalazione => segnalazione.resolved)
  }

  get inCorso () {
    return this.list
      .filter(segnalazione => !segnalazione.resolved)
  }

  get name() {
    return 'segnalazioni'
  }

  get priority() {
    return 1003;
  }

  async onReconnection() {
    if (this.fetched) {
      await this.fetch();
    } else {
      if (this.list.length > 0) {
        for (let i = 0; i < this.list.length; i++) {
          await this.get(this.list[i].id);
        }
      }
    }
  }

  async reload() {
    if (this.fetched) {
      await this.fetch();
    } else {
      for (let i = 0; i < this.list.length; i++) {
        await this.get(this.list[i].id, true)
      }
    }
  }

  add(segnalazione) {
   
    this.list = [...this.list, segnalazione];
  }

  async create(segnalazione) {
    if (
      !segnalazione.coords
      || !segnalazione.coords.lat
      || !segnalazione.coords.lng  
    ) throw new Error('posizione non disponibile');
    const isOnline = await this.manager.controllers.connection.isOnline();
    if (!isOnline) {
      message.info('Al momento non sei online, la segnalazione verra\' inviata automaticamente dopo la riconnessione')
      await this.manager.controllers.connection.addToBacklog(this.name, 'create', [segnalazione]);
      return;
    }
    const controllers = this.manager.controllers;
    if (segnalazione.foto) {
      const numFoto = segnalazione.foto.length;
      for (let i = 0; i < numFoto; i++) {
        const foto = segnalazione.foto[i].file;
        message.loading({
          content: `Caricando foto ${i+1} di ${numFoto}`,
          key: `foto-${i}`
        });
        console.dir(foto);
        const {key} = await Storage
          .put(`${controllers.eventi.current.id}/tappe/${controllers.tappe.current.id}/codice-${segnalazione.codice}/${foto.name.split('.').slice(0, -1)}-${new Date().getTime()}.${foto.name.split('.').slice(-1)}`, foto);
        segnalazione.foto[i] = key;
        message.success({
          content: `Foto ${i+1} di ${numFoto} caricata`,
          key: `foto-${i}`
        });
      }
      
    }
    const result = await controllers.api.post(`/eventi/${controllers.eventi.current.id}/segnalazioni`, {
      body: segnalazione
    });
  }

  
  async getAssignedTeams(id) {
    return await this.manager.controllers.teams.getByAssignement(id)
  }

  async get(id, nocache = null) {
    if (!nocache && this.map[id]) {
      return this.map[id];
    } else {
      const currentEvent = this.manager.controllers.eventi.current;
      if (currentEvent) {
        const result = await this.manager.controllers.api.get(`/eventi/${currentEvent.id}/segnalazioni/${id}`);
        this.list = [...this.list, result.Item];
        return result.Item;
      }
    }
  }

  async fetch() {
    const currentEvent = this.manager.controllers.eventi.current;
    if (currentEvent) {
      const result = await this.manager.controllers.api.get(`/eventi/${currentEvent.id}/segnalazioni`);
      this.list = result.Items;
      this.fetched = true;
      
    }
  }

  find(id) {
    return this.list.findIndex(segnalazione => segnalazione.id === id);
  }

  replace(id, valore) {
    const temp = [...this.list];
    const idx = this.find(id);
    if (idx !== -1) {
      temp[idx] = valore;
      this.list = temp;
    }
  }

  replace(valore) {
    const temp = [...this.list];
    const idx = this.find(valore.id);
    if (idx !== -1) {
      temp[idx] = valore;
      this.list = temp;
    }
  }

  setCurrent(segn) {
    this.current = segn
  }
  

  async resolve(id) {
    const online = await this.manager.controllers.connection.isOnline();
    if (!online) {
      message.info('Al momento non sei online. La risoluzione verrà notificata non appena tornerai online\'')
      this.manager.controllers.connection.addToBacklog(
        this.name,
        'resolve',
        [id]
      )
      return;
    }
    const currentEvent = this.manager.controllers.eventi.current;
    if (currentEvent) {
      const result = await this.manager.controllers.api.post(`/eventi/${currentEvent.id}/segnalazioni/${id}/resolve`)
      this.replace(id, result.Item);
    }
  }
}

export default decorate(Segnalazioni, {
  list: observable,
  current: observable,
  map:computed,
  risolte: computed,
  inCorso: computed,
  fetch: action,
  resolve: action
});