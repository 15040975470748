import React, { useState } from 'react';
import styled from 'styled-components';
import { Form, Input, Button, Alert } from 'antd';
import { MailOutlined } from '@ant-design/icons';

import cm from '../../controllers';
import { navigate } from '@reach/router';

const Titolo = styled.h1`
  color: white;
  margin-top: 10vh;
  margin-bottom: 30px;
`

const CustomForm = styled(Form)`
  
`;

const RequestPasswordReset = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

    return <>
      <Titolo>Inserisci la tua email per richiedere il ripristino della password</Titolo>
      <Alert style={{
        opacity: error  ? 1 : 0,
        transition: 'opacity 300ms linear',
        marginBottom: '15px'
      }} message={error} />
      <CustomForm onFinish={async (values) => {
        try {
          setLoading(true);
          setError(null);
          await cm.controllers.auth.forgotPassword(values.email);
          navigate(`/auth/reset-password?email=${values.email}`);
        } catch (e) {
          switch (e.code) {
            case 'UserNotFoundException':
              setError('Email sconosciuta');
              break;
            default:
              setError('Errore sconosciuto');
              break;
          }
        } finally {
          setLoading(false);
        }
      }}>
        <Form.Item 
          name="email" 
          rules={[{required:true, message: 'Campo richiesto'}, {type: 'email',  message:'Email invalida'}]}
          hasFeedback
        >
          <Input 
            type="email"
            placeholder="Email"
            prefix={<MailOutlined style={{
              paddingRight: '10px'
            }} />} 
          />
        </Form.Item>
        
        <Form.Item style={{marginTop: '50px'}}>
          <Button type="primary" block htmlType="submit" loading={loading}>Invia</Button>
        </Form.Item>
      </CustomForm>

    </>
};

export default RequestPasswordReset;