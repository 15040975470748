import { Button, Result } from 'antd';
import React, { useMemo } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const Error = (props) => {
  console.dir(props)
  const subtitle = useMemo(() => {
    if(!props.location.state) return ''
    switch(props.location.state.error) {
      case 'Nessun evento disponibile':
        return 'Nessun evento attivo in corso trovato. Riprova più tardi'
      default:
        return ''
    }
  }, [props.location.state]);
  const title = useMemo(() => {
    if (!props.location.state) return 'Errore sconosciuto';
    else return props.location.state.error
  }, [props.location.state])

  return  <Container><Result
    style={{
      width: '100%'
    }}
    status="error"
    title={title}
    subTitle={subtitle}
    extra={[
      <Button type="primary" key="retry" onClick={() => {
        window.location = '/'
      }}>
        Ricarica
      </Button>,
    ]}
  /></Container>
}

export default Error;