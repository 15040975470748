import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { Upload, Modal, Form, Button } from 'antd';
import { PlusOutlined, DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
 
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

const UploadButton = styled.button`
  width: 100px;
  height: 100px;
  background: #0f1925;
  border: 1px dashed #3e7acb;
  outline: 0;
`;

const List = styled.ul`
  list-style:none;
  padding: 0;
  li {
    vertical-align: top;
    display: inline-block;
    width: 100px;
    height: 100px;
    margin-right: 10px;
    margin-bottom: 10px;
    img {
      width: 100px;
      height: 100px;
      fit-object: cover;
    }
  }
`;


const ImageContainer = styled.li`
  position: relative;
`;



const PicturesWall = ({onChange}) => {
  const [fileList, setFileList] = useState([]);

  useEffect(() => {onChange(fileList)}, [fileList]);

  return (
    <List className="clearfix">
      {
        fileList.map(file => {
          return <ImageContainer key={`file-${file.id}`}>
            <img src={file.base64}/>
          </ImageContainer>
        })
      }
      <li>
        <Upload
          showUploadList={false}
          onRemove={file => {
            const temp = [...fileList];
            const idx = temp.findIndex(item => item.file === file);
            if (idx > -1) {
              temp.splice(idx, 1)
            }
            setFileList(temp)
          }}
          beforeUpload={async (file) => {
            const id = fileList.length > 0
              ? fileList.slice(-1)[0].id + 1
              : 1;
            const base64 = await getBase64(file);
            setFileList([...fileList, {
              id, 
              base64,
              file
            }]);
            return false;
          }}
        >
          <UploadButton type="button">
            <PlusOutlined />
            <div className="ant-upload-text">Carica foto</div>
          </UploadButton>
        </Upload>
      </li>
  </List>
  )
}

export default PicturesWall;