const Auth = {
  region: 'eu-central-1',
  userPoolId: 'eu-central-1_4WTK42wUj',
  userPoolWebClientId: '2q0l2f6ms5p36pkqp6k5d2j29c',
  mandatorySignin: true,
  identityPoolId: 'eu-central-1:651c5472-6d23-4c4b-9bc2-bf997a757d58'
};

const API = {
  endpoints: [
    {
      name: 'myApi',
      region: 'eu-central-1',
      endpoint: 'https://5v808uams4.execute-api.eu-central-1.amazonaws.com/dev', 
    }
  ]
}

const Storage = {
  AWSS3: {
    bucket: 'com.gta.assets.prod',
    region: 'eu-central-1',
    identityPoolId: Auth.identityPoolId
  }
}


export default {
  Auth,
  API,
  Storage
}