import React, { useState } from 'react';
import styled from 'styled-components';
import { Form, Layout, PageHeader, Tag, Input, Select, Button, Typography, message } from 'antd';
import { navigate, useNavigate } from '@reach/router';
import CustomPageHeader from '../../../../components/custom-page-header';
import LoadingPage from '../../../../components/loading-page';
import { SEGNALAZIONI_COLORS } from '../../../../controllers/Segnalazioni';

import cm from '../../../../controllers';
import CustomSelect from '../../../../components/custom-select';

const {Content} = Layout;

const WhiteForm = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

 
  return <Layout style={{height: '100%'}}>
    <Typography.Title level={4}>Crea segnalazione <Tag
      style={{...SEGNALAZIONI_COLORS.bianco, border: 0}}
    >Bianca</Tag></Typography.Title>
    <Content style={{minHeight: 'calc(100% - 72px)', position: 'relative'}}>
      <Form
        onFinish={async (values) => {
          try {
            if (!cm.controllers.posizione.current) {
              message.error('impossibile creare la segnalazione. Geolocalizzazione non disponibile');
              return;
            } 
            setLoading(true);
            const segnalazione = {
              ...values,
              coinvolti: [{tipologia: 'concorrente', numeroGara: values.coinvolti}],
              codice: 'bianco',
              coords: {
                lat: cm.controllers.posizione.current.latitude,
                lng: cm.controllers.posizione.current.longitude
              },
              tappa: cm.controllers.tappe.current.id
            }
            await cm.controllers.segnalazioni.create(segnalazione);
            await navigate('/')
          } catch (e) { 
            message.error('Errore durante la creazione della segnalazione')
          } finally {
            setLoading(false);
          }
        }}
        labelAlign="left"
        form={form}
        wrapperCol={{lg: 24}}
        labelCol={{lg: 24}}
      >
        <Form.Item label="Numero di gara concorrente" rules={[
          {required: true, message: 'Campo obbligatorio'}
        ]} name="coinvolti">
          <Input placeholder="Inserisci il numero di gara" />
        </Form.Item>

        <Form.Item label="Tipologia" name="dinamica" rules={[
          {required: true, message: 'Campo obbligatorio'}
        ]}>
          <CustomSelect placeholder="Seleziona la tipologia">
            <option>Sosta ristoro</option>
            <option>Guida pericolosa</option>
          </CustomSelect>
        </Form.Item>

        <Form.Item label="Note aggiuntive" name="note">
          <Input.TextArea placeholder="Inserisci eventuali note" />
        </Form.Item>
      </Form>

      <Button block style={{
        marginTop: '20px'
      }} type="primary" size="large" loading={loading} onClick={() => form.submit()}>Invia</Button>

    </Content>
  </Layout>
}

export default WhiteForm;