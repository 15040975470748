import Amplify from './amplify';
import Websocket from './websocket';
import api from './api';
import db from './db';
import firebase from './firebase';

export default {
  Amplify,
  websocket: Websocket,
  api,
  db,
  firebase
};