import React, { useCallback, useEffect, useRef, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { observer } from 'mobx-react';

import cm from '../../../controllers';
import styled, {keyframes} from 'styled-components';
import { reaction } from 'mobx';
import MarkerSegnalazioni from '../../marker-segnalazioni'
import { AimOutlined } from '@ant-design/icons';
import { navigate } from '@reach/router';

const flash = keyframes`
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
`;

const MyPosition = styled.div`
  width: 13px;
  height: 13px;
  border-radius: 100%;
  background: #fe7d07;
  position:relative;
  &:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 100%;
    box-shadow: 0 0 10px 3px #fe7d07;
    animation: ${flash} 1s infinite alternate;
  }
`;

const CenterButton = styled.button`
  position: absolute;
  bottom: ${props => props.hasCurrent ? '120px' : '20px'};
  left: 20px;
  background: #fe7d07;
  width: 38px;
  height: 38px;
  z-index: 998;
  border: none;
  border-radius: 100%;
  font-size: 18px;  
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
  transition: all 300ms linear;
  &[disabled] {
    background: grey;
    color: black;
    opacity: 0.5;
  }
`;

const Map = observer(() => {
  const layers = useRef();
  const [google, setGoogle] = useState(null);
  const [center, setCenter] = useState(true);


  useEffect(() => {
    let r = [];
    reaction(
      () => cm.controllers.posizione.current,
      (current, reaction) => {
        r.push(reaction);
        if (google && current && center) {
          google.map.setCenter({
            lat: current.latitude,
            lng: current.longitude
          });
        }
      },
      {fireImmediately: true}
    )
    reaction(
      () => cm.controllers.tappe.current,
      (tappa, reaction) => {
        r.push(reaction);
        if (tappa && google) {
          if (layers.current) {
            layers.current.forEach(layer => layer.setMap(null));
          }
          layers.current = [];
          if (tappa.settori) {
            const config = cm.controllers.config.get('Amplify').Storage
            tappa.settori
              .map(settore => `https://s3.${config.AWSS3.region}.amazonaws.com/${config.AWSS3.bucket}/public/${settore.file}`) 
              .forEach(url => {
                layers.current.push(
                  new google.maps.KmlLayer(url, {
                    map: google.map,
                    suppressInfoWindows: false,
                    preserveViewport: true
                  })
                )
              })
          }
        }
      },
      {fireImmediately: true}
    )
    return () => r.forEach(item => item.dispose());
  }, [google]);


  const handleApiLoading = useCallback((g) => {
    setGoogle(g);
  }, []);

  const onCenterChange = useCallback(() => {
    setCenter(false);
  }, [])

  const onRecenter = useCallback(() => {
    setCenter(true);
    if (google && cm.controllers.posizione.current) {
      console.dir(cm.controllers.posizione.current);
      google.map.setCenter({
        lat: cm.controllers.posizione.current.latitude,
        lng: cm.controllers.posizione.current.longitude
      })
    }
  }, [google]);


  return  <>
    <GoogleMapReact
      bootstrapURLKeys={{ key: 'AIzaSyDtTtWDz9AynV0hBW2_WVZqfKOaoHpxrsc' }}
      defaultCenter={{
          lat: 45.5114,
          lng: 10.1611
      }}
      defaultZoom={11}
      options={{
        fullscreenControl: false,
        zoomControl: false,
        gestureHandling: 'greedy'
      }}
      style={{height: 'calc(100% - 64px)'}}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={handleApiLoading}
      onDragEnd={onCenterChange}
      
    >
      {
        cm.controllers.posizione.current
          ? <MyPosition lng={cm.controllers.posizione.current.longitude} lat={cm.controllers.posizione.current.latitude} />
          : null
      }
      {
        cm.controllers.segnalazioni.list &&
        !cm.controllers.segnalazioni.current
          ? cm.controllers.segnalazioni.list
            .filter(item => !item.resolved)
            .map(item => {
              return <MarkerSegnalazioni
                key={item.id}
                id={item.id} 
                onClick={() => navigate(`/segnalazioni/${item.id}`)}
                codice={item.codice} 
                {...item.coords} 
              />
            })
          : null
      } 
      {
        cm.controllers.segnalazioni.current
          ? <MarkerSegnalazioni codice={cm.controllers.segnalazioni.current.codice} {...cm.controllers.segnalazioni.current.coords} />
          : null
      } 
    </GoogleMapReact>
    <CenterButton hasCurrent={!!cm.controllers.segnalazioni.current} disabled={center} onClick={onRecenter}><AimOutlined /></CenterButton>
  </>
});

export default Map;