import React, { useState } from 'react';
import styled from 'styled-components';
import { Form, Layout, PageHeader, Tag, Input, Select, Button, Empty, List, Typography, Space} from 'antd';
import { navigate, useNavigate } from '@reach/router';

import cm from '../../../controllers';
import { observer } from 'mobx-react';
import { SEGNALAZIONI_COLORS } from '../../../controllers/Segnalazioni';

const {Content} = Layout;

const SingleSegnalazione = ({item}) => {
  return <div  style={{width: '100%', padding: '10px 0' ,display: 'flex', alignItems:'center', justifyContent: 'space-between'}}>
      <div>
        <h3><Space>Codice <Tag
          style={{
            ...SEGNALAZIONI_COLORS[item.codice],
            border: 0
          }}
        >{item.codice.charAt(0).toUpperCase()}{item.codice.slice(1)}</Tag> 
        </Space>
        </h3>
        <small>{new Date(item.createdAt).toLocaleString()}</small>
      </div> 
      <div>
        {
        item.coords && cm.controllers.posizione.current
          ? `a ${(cm.controllers.posizione.distance(item.coords) * 100).toFixed(2)} km di distanza`
          : ''
      }

      </div>
    </div>
}

const ListSegnalazioni = observer(() => {
  const data = cm.controllers.segnalazioni.list
    .filter(s => s.resolved)
    .sort((a, b) => new Date(a.createdAt).getTime() < new Date(b.createdAt).getTime() ? 1 : -1);

  return data.length > 0
    ? <List
        dataSource={data}
        renderItem={(item) => (
          <List.Item onClick={() => navigate(`/segnalazioni/${item.id}`)}>
            <SingleSegnalazione item={item} />
          </List.Item>
        )}
      />
    : <Empty description="Nessuna segnalazione in corso"  style={{color: 'white'}}/>

})


const Risolte = () => {

 
  return <Layout style={{height: '100%'}}>
    <Typography.Title level={4}>Segnalazioni risolte</Typography.Title>


    <Content>
      <ListSegnalazioni  />
    </Content>
  </Layout>
}

export default Risolte;