import _ from 'lodash';
import { decorate, observable} from 'mobx';
import noInternet from 'no-internet';
import {DB_STATUS} from './DB';
import Controller from '../utils/Controller';


class Connection extends Controller {
  constructor(options, manager) {
    super(options, manager);
    this.offline = false;
    this.interval = null;
    this.EXECUTING = false;
    this.list = [];
    this.events = {
      'reconnect': [],
      'disconnect': []
    };
  }
  
  get name() {
    return 'connection'
  }

  get priority() {
    return 999;
  }


  addEventListener (event, callback) {
    if (!this.events[event]) return;

    this.events[event].push(callback);
  }

  removeEventListener(event, callback) {
    if (!this.events[event]) return;

    this.events[event] = this.events[event].filter(item => item.callback !== callback);
  }

  async isOnline() {
    const offline = await noInternet();
    if (offline) this.check()
    return !offline;
  }

  async start() {
    super.start();
    setTimeout(this.check.bind(this), 1000);
    setTimeout(this.updateList.bind(this), 2000);
  }

  async updateList() {
    this.list = await this.manager.controllers.db.list()
  }

  async addToBacklog(controller, action, params) {
    await this.manager.controllers.db.add({
      controller,
      action,
      params
    });
    await this.updateList();
  }

  async clearBacklog() {
    if (this.EXECUTING) return ;
    if (this.manager.controllers.db.DB_STATUS !== DB_STATUS.CONNECTED) {
      this.dbTimeout = setTimeout(this.clearBacklog.bind(this), 1000);
      return
    }
    const list = await this.manager.controllers.db.list();
    this.EXECUTING = true;
    for (let i = 0; i < list.length; i++) {
      const online = await this.isOnline();
      if (!online) {
        this.EXECUTING = false;
        return;
      }
      const {key, value} = list[i];
      const fn = this.manager.controllers[value.controller][value.action].bind(this.manager.controllers[value.controller]);
      await fn(...value.params);
      await this.manager.controllers.db.delete(key);
      await this.updateList();
    }
    this.EXECUTING = false;
  }

  async check() {
    const online = !(await noInternet());
    if (online) {
      if (this.timeout) clearTimeout(this.timeout);
      if (this.events.reconnection) {
        for (let i = 0; i < this.events.reconnection.length; i++) {
          await this.events.reconnection[i]();
        }
      }
      setTimeout(async () => await this.clearBacklog(), 3000)
      this.offline = false;
    } else {
      if (this.events.reconnection) {
        for (let i = 0; i < this.events.reconnection.length; i++) {
          await this.events.disconnect[i]();
        }
      }
      this.offline = true;
      this.timeout = setTimeout(this.check.bind(this), 1000);
    }
  }
 
}

export default decorate(Connection, {
  offline: observable,
  list: observable
});