import { Popover, Tooltip } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components'
import cm from '../../controllers'

const Container = styled.div`
  position: fixed;
  top: 80px; left: 10px;
  z-index: 999;
  background: #1a1a1a;
  padding: 10px 20px;
  border-radius: 5px;
  max-width: 60%;

`;

const generateString = (item) => {
  if (
    item.value.controller === 'segnalazioni' &&
    item.value.action === 'create'
  ) {
    return `Creazione segnalazione codice ${item.value.params[0].codice}`
  } else if (item.value.controller === 'segnalazioni' &&
  item.value.action === 'resolve') {
    return `Risoluzione segnalazione`;
  } else {
    return null
  }
}

const generateContent = (list) => {
  return <ul style={{margin: 0, paddingLeft: '10px'}}>
    {list.map(item => <li key={item.key}>{generateString(item)}</li>)}
  </ul>
}

const HangingRequest = observer(() => {
  if (cm.controllers.connection.list && 
    cm.controllers.connection.list.length > 0) {
    return <Container>
      <Popover placement="bottomLeft" trigger="click" content={generateContent(cm.controllers.connection.list)}>
        Ci sono {cm.controllers.connection.list.length} {cm.controllers.connection.list.length === 1 ? 'operazione' : 'operazioni'} in sospeso per assenza di connessione
      </Popover>
    </Container>
  } else {
    return null;
  }
});

export default HangingRequest;