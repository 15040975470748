import Controller from '../utils/Controller';
import { decorate, computed, action, observable } from 'mobx';
import { message } from 'antd';

class Eventi extends Controller {
  constructor(options, manager) {
    super(options, manager);
    this.watch = null;
    this.current = null;
    this.notify = true;
    this.delta = 0;
    this.first = true;
  }

  get name() {
    return 'posizione'
  }

  get priority() {
    return 1004;
  }

  

  async start() {
    super.start();
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.updatePosition.bind(this));
      setInterval(() => {
        navigator.geolocation.getCurrentPosition(this.updatePosition.bind(this), console.error.bind(console))
      }, 10000)
    } else {
      message.error('La versione del browser corrente non supporta la geolocalizzazione')
    }
    
    setInterval(() => {
      if ((
        this.notify && 
        this.current && 
        this.manager.controllers.eventi.current &&
        this.manager.controllers.websocket.isOpen()
      )) {
        this.manager.controllers.websocket.send(JSON.stringify({
          action: 'position_update',
          body: {
            coords: this.current,
            event: this.manager.controllers.eventi.current.id
          }
        }))
      }
    }, 60000)
  }

  distance(coords) {
    const {current} = this;
    if (!current) return 0;
    if (current.latitude === coords.latitude && coords.longitude === current.longitude) return 0; 

    const deltaHorizontal = (current.longitude - coords.lng) * Math.cos(current.longitude)
    const deltaVertical = (current.latitude - coords.lat);

    return Math.sqrt(
      Math.pow(deltaHorizontal, 2) +
      Math.pow(deltaVertical, 2)
    );
  } 

  updatePosition (position) {
    this.delta = this.distance({lat: position.coords.latitude, lng: position.coords.longitude});
    this.current = {
      latitude: position.coords.latitude,
      longitude: position.coords.longitude
    };
    this.lastUpdate = position.timestamp;
  }

  

}

export default decorate(Eventi, {
  currentPosition: observable,
  notify: observable,
  updatePosition: action
});