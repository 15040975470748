import _ from 'lodash';
import Controller from '../utils/Controller';

export const DB_STATUS = {
  INITIAL: 0,
  CONNECTING: 1,
  CONNECTED: 2
}

class DB extends Controller {
  constructor(options, manager) {
    super(options, manager);
    this.DB_STATUS = DB_STATUS.INITIAL;
    this.db = null;
    this.connection = null;
  }

  get name() {
    return 'db'
  }
 
  get priority() {
    return 990;
  }

  async init () {
    super.init();
    this.config = this.manager.controllers.config.get('db');
  }
 
  async start() {
    super.init();
    this.connection = this.connect();
  }

  connect() {
    this.DB_STATUS = DB_STATUS.CONNECTING;
    const conn = indexedDB.open(this.config.name);
    conn.onsuccess = (event) => {
      this.db = event.target.result;
      this.DB_STATUS = DB_STATUS.CONNECTED;
    }
    conn.onupgradeneeded = (event) => {
      const db = event.target.result;
      this.db = db;
      
      const objectStore = db.createObjectStore(this.config.objectStore, {
        autoIncrement: true
      });

      objectStore.transaction.complete = () => {
        this.DB_STATUS = DB_STATUS.CONNECTED;
      }
    }
  }

  add(obj) {
    return new Promise((resolve, reject) => {
      const request = this.db
        .transaction(this.config.objectStore, 'readwrite')
        .objectStore(this.config.objectStore)
        .add(obj);

      request.onsuccess = resolve;
      request.onerror = reject;
    })
    
  }

  list() {
    return new Promise((resolve, reject) => {
      const entries = [];
      const store = this.db.transaction(this.config.objectStore, 'readwrite').objectStore(this.config.objectStore);
      store.openCursor().onsuccess = (event) => {
        const cursor = event.target.result;
        if (cursor) {
          entries.push({key: cursor.key, value: cursor.value});
          cursor.continue();
        } else {
          resolve(entries);
        }
      }
    });
  }

  delete(key) {
    return new Promise((resolve, reject) => {
      const request = this.db
        .transaction([this.config.objectStore], 'readwrite')
        .objectStore(this.config.objectStore)
        .delete(key);
      request.onsuccess = resolve;
      request.onerror = reject
    })
  }


}

export default DB;