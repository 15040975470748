import  React from 'react';
import {Form, Input, Button, Layout} from 'antd';
import styled from 'styled-components';
import cm from '../../../controllers';
import { navigate } from '@reach/router';

const Titolo = styled.h1`
  color: white;
  margin-top: 75px;
`

const CustomForm = styled(Form)`
  color: #fff;
  .ant-form-item-label>label{
    color: #fff !important;
  }
`;

const NewPasswordRequired = () => {
  
  const [form] = Form.useForm();

  return (<Layout style={{background: 'transparent'}}>
    <Titolo>Cambia password</Titolo>
    <p style={{color: '#fff'}}>Serve modificare la password preimpostata per continuare</p>
    <p style={{color: '#fff'}}>
      La password deve:
    </p>
    <ul style={{color: '#fff'}}>
      <li>avere almeno 8 caratteri</li>
      <li>contenere una maiuscola</li>
      <li>contenere una minuscola</li>
      <li>contenere un numero</li>
    </ul>
    <CustomForm
      style={{color: '#fff'}}
      form={form}
      labelCol={{lg: 24}}
      labelAlign="left"
      wrapperCol={{lg: 24}}
      onFinish={async (values) => {
        try {
          await cm.controllers.auth.completeNewPassword(values.password);
        } catch(e) {
          navigate('/')
        }
      }}
    >
      <Form.Item
        name="password"
        label="Password"
        hasFeedback
        dependencies={['password']}
        rules={[
          {required: true, message: 'Campo necessario'},
          () => ({
            validator (rule, value) {
              if (/^((?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9]).{8,})\S$/.test(value)) {
                return Promise.resolve()
              }         
              return Promise.reject('La password non soddisfa i requisiti');
            }
          })
        ]}
      > 
        <Input.Password />
      </Form.Item>
      <Form.Item
        name="confirm"
        dependencies={['password']}
        label="Conferma la password"
        hasFeedback
        rules={[
          {required: true, message: 'Campo necessario'},
          ({getFieldValue}) => ({
            validator (rule, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject('Le due password non coincidono');
            }
          })
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item style={{textAlign: 'right'}}>
        <Button type="primary" htmlType="submit" block>Invia</Button>
      </Form.Item>
    </CustomForm>
  </Layout>
  );
}

export default NewPasswordRequired;