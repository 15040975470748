import React from 'react';
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';

const Container = styled.div`
width: 100%;
border: 1px solid #3e7acb;  
min-height: 32px;
border-radius: 2px;
display: flex;
align-items: center;
position: relative;
background:#0f1925;
&:after {
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  content: "${props => props.placeholder ? props.placeholder : ''}";
  color: #ececec;
  font-size: 14px;
  padding: 4px 6px;
  display: ${props => props.placeholder && !props.value ? 'block' : 'none' };
  pointer-events: none;
}
select {
  border: none;
  opacity: ${props => props.placeholder && !props.value ? 0 : 1 };
  background: #0f1925;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  height: 100%;
  width: 100%;
  padding: 1px 8px;
  color: #fafafa;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  outline: 0;
}
`;

const CustomSelect = (props) => {
  return <Container placeholder={props.placeholder} value={props.value}>
    <select {...props} value={(props.value ? props.value : null)} disabled={props.disabled || props.loading} onChange={(e) => {
      if (props.onChange){
        if (props.multiple) {
          props.onChange(Array.from(e.target.selectedOptions)
            .map(item => item.value))
        } else {
          props.onChange(e.target.value);
        }
      }
    }}>
      {
        props.loading
          ? <option><LoadingOutlined /></option>
          : props.multiple 
            ? props.children
            : [<option  value={null} style={{opacity:0}} key={'select-default-option'} > </option>,...props.children]
      }
    </select>
  </Container>
}

export default CustomSelect;