import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { Form, Layout, Tag, Input, Button, PageHeader, message, Typography } from 'antd';
import { navigate, useNavigate } from '@reach/router';
import Coinvolti from './coinvolti';
import Dinamica from './dinamica';
import RichiestaIntervento from './richiesta-intervento';
import PicturesWall from './pictures-wall';

import cm from '../../../../controllers';
import { SEGNALAZIONI_COLORS } from '../../../../controllers/Segnalazioni';

const {Content, Footer} = Layout;


const YellowForm = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

 
  return  <Layout style={{minHeight: '100%'}}>
    <Typography.Title level={4}>Crea segnalazione <Tag
      style={{...SEGNALAZIONI_COLORS.giallo, border: 0}}
    >Gialla</Tag></Typography.Title>
    <Content style={{minHeight: '100%'}}>
      <Form
        onFinish={async (values) => {
          try {
            if (!cm.controllers.posizione.current) {
              message.error('impossibile creare la segnalazione. Geolocalizzazione non disponibile');
              return;
            } 
            setLoading(true);
            const segnalazione = {
              ...values,
              codice: 'giallo',
              dinamica: values.dinamica.map(item => {
                return item.toLowerCase() === 'urto contro ostacolo'
                  ? `${item} (${values.ostacolo})`
                  : item
              }),
              coords: {
                lat: cm.controllers.posizione.current.latitude,
                lng: cm.controllers.posizione.current.longitude
              },
              tappa: cm.controllers.tappe.current.id
            }
            await cm.controllers.segnalazioni.create(segnalazione);
            await navigate('/')
          } catch (e) {
            message.error('Errore durante la creazione')
          } finally {
            setLoading(false);
          }
        }}
        labelAlign="left"
        
        form={form}
        wrapperCol={{lg: 24}}
        labelCol={{lg: 24}}
      >
        <Form.Item label="Veicoli coinvolti" rules={[{required: true, message: 'Campo obbligatorio'}]}>
          <Coinvolti form={form} />
        </Form.Item>
        <Dinamica form={form} rules={[
          { required: true, message: 'Campo obbligatorio' }
        ]} />
        <RichiestaIntervento />
        <Form.Item label="Foto" name="foto">
          <PicturesWall />
        </Form.Item>
        <Form.Item label="Note aggiuntive" name="note">
          <Input.TextArea placeholder="Inserisci eventuali note" />
        </Form.Item>
      </Form>
      <Button
        block 
        loading={loading}
        type="primary"
        size="large"
        onClick={() => form.submit()}>Invia</Button>

    </Content>
  </Layout>
}

export default YellowForm;