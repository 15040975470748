import { navigate } from '@reach/router';
import { Tag, Typography } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';

import cm from '../../../controllers'
import { SEGNALAZIONI_COLORS } from '../../../controllers/Segnalazioni';

const Container = styled.button`
  position: absolute;
  bottom: 0;
  z-index:999;
  background: #15273d;
  width: 100%;
  padding: 20px;
  text-align: left;
  display: ${props => props.visible ? 'block' : 'none'};
`;

const Current = observer(() => {
  return <Container visible={!!cm.controllers.segnalazioni.current} onClick={() => {
    navigate(`/segnalazioni/${cm.controllers.segnalazioni.current?.id}`)
  }}>
    {
      cm.controllers.segnalazioni.current     
        ? <><header style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}>
          <Typography.Title level={4}>
            Codice <Tag
              style={{
                ...SEGNALAZIONI_COLORS[cm.controllers.segnalazioni.current.codice],
                border: 0,
                fontWeight: 'normal'
              }}
            >{cm.controllers.segnalazioni.current.codice}</Tag>
          </Typography.Title>
          <span>
            {
              cm.controllers.posizione.current
                ? `${cm.controllers.posizione.distance(cm.controllers.segnalazioni.current.coords).toFixed(2)} km`
                : null
            }
          </span>
        </header>
        <span>{new Date(cm.controllers.segnalazioni.current.createdAt).toLocaleString()}</span>
        </>
      : null
    }
    
  </Container>
});

export default Current;