import _ from 'lodash';

export const STATUSES = {
  INITIALIZING: 0,
  INITIALIZED: 1,
  STARTING: 2,
  STARTED: 3,
  PAUSING: 4,
  PAUSED: 5,
  STOPPING: 6,
  STOPPED: 7
};

class ControllerManager {
  
  constructor(controllers) {
    this._controllers = controllers;
    this._instances = controllers
      .map(controller => new controller.Class(controller.options || {}, this))
      .reduce((agg, current) => {
        agg[current.name] = current;
        return agg;
      }, {})
  }

  async init (controllerName) {
    if (controllerName) {
      const controller = this._instances[controllerName];
      controller.STATUS = STATUSES.INITIALIZING;
      await controller.init();
      controller.STATUS = STATUSES.INITIALIZED;
    } else {
      const arr = _.values(this._instances)
        .sort((a, b) => a.priority - b.priority);

      for (let i = 0; i < arr.length; i++) {
        const cname = arr[i].name;
        await this.init(cname);
      }    
    }
  }

  async start (controllerName) {
    if (controllerName) {
      const controller = this._instances[controllerName];
      if (
          controller.STATUS !== STATUSES.INITIALIZED &&
          controller.STATUS !== STATUSES.PAUSED
        ) {
        await controller.init();
      }
      controller.STATUS = STATUSES.STARTING;
      await controller.start();
      controller.STATUS = STATUSES.STARTED;
    } else {
      const arr = _.values(this._instances)
        .slice()
        .sort((a, b) => a.priority - b.priority);

      for (let i = 0; i < arr.length; i++) {
        const cname = arr[i].name;
        await this.start(cname);
      }
    }
  }

  async pause (controllerName) {
    if (controllerName) {
      const controller = this._instances[controllerName];
      if (controller.STATUS === STATUSES.STARTED) {
        controller.STATUS = STATUSES.PAUSING;
        await controller.pause();
        controller.STATUS = STATUSES.PAUSED;
      }
    } else {
      const arr = _.values(this._instances)
        .sort((a, b) => a.priority - b.priority);

      for (let i = 0; i < arr.length; i++) {
        const cname = arr[i].name;
        await this.pause(cname);
      }
    }
  }

  async stop (controllerName) {
    if (controllerName) {
      const controller = this._instances[controllerName];
      if (
        controller.STATUS === STATUSES.STARTED ||
        controller.STATUS === STATUSES.PAUSED
      ) {
        controller.STATUS = STATUSES.STOPPING;
        await controller.stop();
        controller.STATUS = STATUSES.STOPPED
      } else {
        const arr = _.values(this._instances)
        .sort((a, b) => a.priority - b.priority);

        for (let i = 0; i < arr.length; i++) {
          const cname = arr[i].name;
          await this.stop(cname);
        }
      }
    }
  }

  async remove (controllerName) {
    this._controllers = this._controllers.filter(controller => controller.name !== controllerName)
    await this.stop(controllerName);
    delete this._instances[controllerName];
  }

  async attach(controller, options) {
    this._controllers.push(controller);
    this._instances[controller.name] = (new controller.Class(controller.options || {}, this));
    if (options.autoInit) {
      await this.init(controller.name);
    }
    if (options.autoStart) {
      await this.start(controller.name);
    }
  }

  get controllers() {
    return this._instances;
  }

}

export default ControllerManager;