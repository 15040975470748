import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { Layout, PageHeader, Radio, Button, Typography} from 'antd';
import { useNavigate } from '@reach/router';
import { WarningOutlined, FireOutlined, RestOutlined, CarOutlined } from '@ant-design/icons';


const {Content, Footer} = Layout;

const Menu = styled.ul`
  margin: 0;
  padding: 0;
  width: 100%;
  padding: 20px 0 0 0;
  li {
    display: block;
    margin-bottom: 10px;
    button {
      border: 2px solid #133B6B;
      border-radius: 5px;
      display: flex;
      width: 100%;
      height: auto;
      padding: 5px;
      text-align: left;
      align-items: center;
    
    }
  }
`;

const IconContainer = styled.div`
  background: ${props => props.background};
  padding: 15px 20px;
  color: ${props => props.color || 'white'};
  font-size: 25px;
  margin-right: 25px;
  border-radius: 5px;
`;

const SendButton = styled.button`
  width: 100%;
  padding: 15px;
  border-radius: 5px;
  background: ${props => props.disabled ? 'gray' : '#89e39e'};
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: black;
  outline: 0;
  cursor:pointer;
 
  pointer-events: ${props => props.disabled ? 'none' : 'all'};
  opacity: ${props => props.disabled ? 0.2 : 1};
  cursor: ${props => props.disabled ? 'no-drop' : 'normal'};
`;

const CustomButton = styled.button`
  outline: 0;
  cursor: pointer;
  transition: background 300ms linear;
  color: ${props => props.selected ? props.selectstyle.color : 'inherit'};
  background: ${props => props.selected ? props.selectstyle.background : 'transparent'};
  h3 {
  color: ${props => props.selected ? props.selectstyle.color : 'inherit'};
  margin: 0;
    transition: color 300ms linear;
  }
  small {
    white-space: break-spaces;
    line-height: 8px;
    transition: color 300ms linear;
  }
`;

const paths = ['rosso', 'giallo', 'blu', 'bianco'];



const SelectType = () => {
  const [value, setValue] = useState(-1);
  const navigate = useNavigate();

  return <Layout style={{
    minHeight: '100%'
  }}>
    <Content style={{
      padding: "0",
    }}>
      <Typography.Title level={4} style={{
        textAlign: 'center',
        marginBottom: 0
      }}>Seleziona il tipo</Typography.Title>
      <Menu buttonStyle="outline">
        <li>
          <CustomButton 
            selectstyle={{background:'#DB3A34', color: 'white'}}
            selected={value === 0}
            onClick={() => navigate(`/nuova-segnalazione/${paths[0]}`)}
          >
            <IconContainer background="#DB3A34" color="white">
              <FireOutlined />
            </IconContainer>
            <div>
              <h3>Rosso</h3>
              <small>Incidenti con feriti e/o incendio</small>
            </div>
          </CustomButton>
        </li>
        <li>
          <CustomButton 
            selectstyle={{background:'#FFC857', color: '#333'}}
            selected={value === 1}
            onClick={() => navigate(`/nuova-segnalazione/${paths[1]}`)}
          >
            <IconContainer background="#FFC857" color="#333" >
              <WarningOutlined />
            </IconContainer>
            <div>
              <h3>Giallo</h3>
              <small>Incidenti con solo danni materiali</small>
            </div>
          </CustomButton>
        </li>    
        <li>
          <CustomButton
            selectstyle={{background:'#3DA5D9', color: '#fff'}}
            selected={value === 2}
            onClick={() => navigate(`/nuova-segnalazione/${paths[2]}`)}
          >
            <IconContainer background="#3DA5D9">
            <CarOutlined />
            </IconContainer>
            <div>
              <h3>Blu</h3>
              <small>Problema meccanico, sanitario lieve, ritiro, altro</small>
            </div>
          </CustomButton>
        </li>    
        <li>
          <CustomButton
            selectstyle={{background:'#f0f0f0', color: '#333'}}
            selected={value === 3}
            onClick={() => navigate(`/nuova-segnalazione/${paths[3]}`)}
          >
            <IconContainer background="#f0f0f0" color="#333">
              <RestOutlined />
            </IconContainer>
            <div>
              <h3>Bianco</h3>
              <small>Sosta ristoro</small>
            </div>
          </CustomButton>
        </li>    
         
      </Menu>
    </Content>
  </Layout>
};

export default SelectType;