import React, { useEffect, useState } from 'react';
import {navigate} from '@reach/router';

const withGuard = (Component) => (props) => {
  const [loading, setLoading] = useState(true) 

  useEffect(() => {
    const execute = async () => {
      if (props.guard) {
        const result = await props.guard();
        if (result === true) {
          setLoading(false);
        } else {
          if (result.redirect) {
            navigate(result.redirect);
          } else {
            navigate('/access-denied')
          }
        }
      }
    }
    execute()

  }, [props.guard])
  
  return loading
    ? loading 
    : <Component {...props} />
};

export default withGuard;