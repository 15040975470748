import React, { useEffect, useState } from 'react'
import { Router } from '@reach/router';

import AuthPages from './pages/auth';
import DashboardPages from './pages/dashboard';

import withGuard from './hoc/withGuard';
import guard from './utils/guard';
import cm from './controllers';
import LoadingPage from './components/loading-page';
import Error from './pages/error';
import { ErrorBoundary } from '@sentry/react';

const DashboardProtected = withGuard(DashboardPages);

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const startControllers = async () => {
      await cm.start();
      setLoading(false);
    }
    startControllers();
  }, [])

  return <ErrorBoundary>
    {
      loading
        ? <LoadingPage text="Caricamento" />
        : <Router style={{height: '100%', overflow: 'auto'}}>
            <AuthPages path="/auth/*" />
            <Error path="/error" />
            <DashboardProtected path="/*" guard={guard} />
        </Router>
    }
  </ErrorBoundary>

}

export default App;