
import {Auth as AwsAuth} from 'aws-amplify';
import { decorate, observable, action } from 'mobx';
import Controller from '../utils/Controller';

const noop = () => {};

class Auth extends Controller {

  constructor(options, manager) {
    super(options, manager);
    this.user = null;
  }

  get isLoggedIn() {
    return !!this.user;
  }

  get name() {
    return 'auth'
  }

  get priority () {
    return 100;
  }

  async start() {
    super.start();
    try {
      this.user = await AwsAuth.currentAuthenticatedUser();
      
    } catch (e) {
      this.user = null
    }
  }

  async login(username, password) {
    this.user = await AwsAuth.signIn(username, password);
    return this.user;
  }

  async logout() {
    await AwsAuth.signOut({
      global: true
    });
    this.user = null;
  }

  async forgotPassword(email) {
    await AwsAuth.forgotPassword(email);
  }

  async resetPassword(email, password, code) {
    await AwsAuth.forgotPasswordSubmit(email, code, password);
  }

  async completeNewPassword(newPassword) {
    try {
      if (!this.user) throw new Error('no user provided');
      await AwsAuth.completeNewPassword(this.user, newPassword, {})
    } catch (e) {
      if (e.message === 'Illegal invocation') {
        return this.user
      } else {
        throw e;
      }
    }
  }

  async getCurrentSession() {

    return AwsAuth.currentSession();
  }

}

export default decorate(Auth, {
  user: observable,
  logout: action,
  login: action,
  start: action
});