import React from 'react';
import {Router} from '@reach/router';
import NewPasswordRequired from './new_password_required';


const Challenge = () => {

  return <Router>
    <NewPasswordRequired path="/NEW_PASSWORD_REQUIRED" /> 
  </Router>
  
};

export default Challenge;