import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Form, Layout, Tag, Select, Button, Input, PageHeader, Typography, message } from 'antd';
import { navigate, useNavigate } from '@reach/router';
import Coinvolti from './coinvolti';
import Dinamica from './dinamica';
import RichiestaIntervento from './richiesta-intervento';
import PicturesWall from './pictures-wall';
import CustomSelect from '../../../../components/custom-select';
import {SEGNALAZIONI_COLORS} from '../../../../controllers/Segnalazioni';

import cm from '../../../../controllers';

const {Content} = Layout;

const CustomForm = styled(Form)`
  padding: 20px 0 0 0;
`;

const RedForm = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  return <Layout style={{minHeight: '100%', overflow: 'auto'}}>
    <Typography.Title level={4}>Crea segnalazione <Tag style={{
      ...SEGNALAZIONI_COLORS.rosso,
      border: 0
    }}>Rosso</Tag></Typography.Title>
    <Content style={{minHeight: '100%',}}>
      <CustomForm
        onFinish={async (values) => {
          try {
            if (!cm.controllers.posizione.current) {
              message.error('impossibile creare la segnalazione. Geolocalizzazione non disponibile');
              return;
            } 
            setLoading(true);
              
            const segnalazione = {
              codice: 'rosso',
              ...values,
              dinamica: values.dinamica.map(item => {
                return item.toLowerCase() === 'urto contro ostacolo'
                  ? `${item} (${values.ostacolo})`
                  : item
              }),
              coords: {
                lat: cm.controllers.posizione.current.latitude,
                lng: cm.controllers.posizione.current.longitude
              },
              tappa: cm.controllers.tappe.current.id
            }
            await cm.controllers.segnalazioni.create(segnalazione);
            await navigate('/')
          } catch (e) {
            message.error('errore durante la creazione');
          } finally {
            setLoading(false);
          }
        }}
        labelAlign="left"
        form={form}
        wrapperCol={{lg: 24}}
        labelCol={{lg: 24}}
      >
        <Form.Item rules={[
          {required: true, message: 'Campo obbligatorio'}
        ]} name="tipo" label="Tipo di incidente" hasFeedback>
          <CustomSelect placeholder="Seleziona la tipologia">
            <option value="grave con feriti">
              Incidente grave con feriti
            </option>
            <option value="lieve con feriti">
              Incidente lieve con feriti
            </option>
            <option value="incendio">
              Incidente con incendio
            </option>
          </CustomSelect>
        </Form.Item>
        <Form.Item label="Veicoli coinvolti" name="coinvolti" rules={[
          {required: true, message: 'Campo obbligatorio'}
        ]}>
          <Coinvolti form={form} />
        </Form.Item>
        <Dinamica form={form} rules={[
          { required: true, message: 'Campo obbligatorio' }
        ]}/>
        <RichiestaIntervento />
        <Form.Item name="foto" label="Foto">
          <PicturesWall />
        </Form.Item>
        <Form.Item label="Note aggiuntive" name="note">
          <Input.TextArea placeholder="Inserisci eventuali note" />
        </Form.Item>
      </CustomForm>
      <Button block loading={loading} type="primary" size="large" onClick={() => form.submit()}>Invia</Button>

    </Content>
  </Layout>
}

export default RedForm;