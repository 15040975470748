import cm from '../controllers';

const authGuard = async () => {
  if (cm.controllers.auth.user) {
    return true;
  } else {
    return {
      redirect: '/auth'
    }
  }
}


export default authGuard;