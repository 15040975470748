import React, { useEffect,  useMemo } from 'react';
import { Layout, Drawer} from 'antd';
import styled from 'styled-components';
import Map from '../../components/dashboard/map';
import Current from '../../components/dashboard/current/current';
import { PlusOutlined } from '@ant-design/icons';
import { navigate, Router, useLocation } from '@reach/router';
import { reaction } from 'mobx';
import cm from '../../controllers';

import SelectType from './segnalazione/select-type';
import RedForm from './segnalazione/forms/rosso';
import YellowForm from './segnalazione/forms/giallo';
import SegnalazioneDetail from '../../components/segnalazioni/detail';
import BlueForm from './segnalazione/forms/blu';
import WhiteForm from './segnalazione/forms/bianco';
import { observer } from 'mobx-react';
import CustomHeader from '../../components/header';
// import LoadingPage from '../../components/loading-page';
// import Success from './segnalazione/success';
// import ChatPage from './chat';
import InCorso from './segnalazione/inCorso';
import Risolte from './segnalazione/risolte';
import Chat from '../../components/chat';
import HangingRequest from '../../components/hanging-request/hanging-request';

// import withS3Src from '../../hoc/withS3Src';
// import config from '../../config';
// import SegnalazioneCorrente from '../../components/segnalazione-corrente';
// import MarkerSegnalazioni from '../../components/marker-segnalazioni';


{/* <span>
{
  state.Auth.isLoggedIn 
    ? <Avatar
    onClick={() => actions.Auth.logout()} style={{backgroundColor: ColorList[state.Auth.user.attributes.email.length % ColorList.length]}}>{state.Auth.user.attributes.email.charAt(0).toUpperCase()}</Avatar>
    : <LoadingOutlined />
}
</span> */}

const {Content} = Layout;

const AddButtonContainer = styled.button`
  position: absolute;
  bottom: ${props => props.hasCurrent ? '120px' : '20px'};
  right: 20px;
  background: #fe7d07;
  width: 38px;
  height: 38px;
  z-index: 998;
  border: none;
  border-radius: 100%;
  font-size: 18px;  
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);

`;
const AddButton = observer(({children, onClick}) => {
  return <AddButtonContainer 
    onClick={onClick}
    hasCurrent={!!cm.controllers.segnalazioni.current}
  >{children}</AddButtonContainer>
});

const FeedbackButton = styled.a`
  position: absolute;
  bottom: ${props => props.hasCurrent ? '120px' : '20px'};
  left: 50%;
  background: #fe7d07;
  z-index: 998;
  border: none;  
  color: white;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
  transform: translateX(-50%);
`;

// const EventoAvatar = withS3Src(Avatar);
const FeedBackButtonObs = observer(() => {
  return <FeedbackButton 
  href="mailto:support@idshooter.com?subject=Feedback applicazione gta"
  hasCurrent={!!cm.controllers.segnalazioni.current}>Invia Feedback</FeedbackButton>
})


const CustomDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 0;
    height: 100%;
  }
`;

const Dashboard = () => {

  const location = useLocation();
  
  const isVisible = useMemo(() => location.pathname !== '/' && location.pathname !== '/chat', [location]);
  const isChatVisible = useMemo(() => location.pathname === '/chat', [location]);

  return <Layout style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
    <CustomHeader />
    <Content style={{height: '100%'}}>
      <HangingRequest />
      <Map />
      <Current /> 
      <AddButton onClick={() => {
        navigate('/nuova-segnalazione')
      }}> <PlusOutlined /> </AddButton>
      <FeedBackButtonObs />
      <CustomDrawer visible={isChatVisible} onClose={() => navigate(-1)} width="90%" placement="right">
        <Router style={{height: '100%'}}>
          <Chat path="/chat" />
        </Router>
      </CustomDrawer>
      <Drawer 
        visible={isVisible} 
        onClose={() => navigate(-1)} 
        placement="bottom"
        height="80%"
      >
        <Router>
          <SelectType path="/nuova-segnalazione" />
          <RedForm path="/nuova-segnalazione/rosso" />
          <YellowForm path="/nuova-segnalazione/giallo" />
          <BlueForm path="/nuova-segnalazione/blu" />
          <WhiteForm path="/nuova-segnalazione/bianco" /> 
          <InCorso path="/segnalazioni" />
          <Risolte path="/segnalazioni-risolte"/>  
          <SegnalazioneDetail path="/segnalazioni/:id" />
        </Router>
      </Drawer>
    </Content>
    
  </Layout>
  
  
}

export default Dashboard;