import React, { useEffect } from 'react';
import { navigate, Router } from '@reach/router';
import Dashboard from './Dashboard';
import { reaction } from 'mobx';
import moment from 'moment';


import cm from '../../controllers'


const DashboardPages = (props) => {



  useEffect(() => {
    let r=[];
    cm.controllers.websocket.connect();
    const load = async () => {
      cm.controllers.chat.connect();
      await cm.controllers.eventi.fetch();

      if (cm.controllers.eventi.list.length === 0) {
        navigate('/error', {
          state: {
            error: 'Nessun evento disponibile'
          }
        })
      }
      let found = false;

      for (let i = 0 ; i < cm.controllers.eventi.list.length; i++) {
        const evento = cm.controllers.eventi.list[i];
        await cm.controllers.teams.fetch(evento.id);
        if (cm.controllers.teams.list.length > 0) {
          found = true;
          cm.controllers.teams.setCurrent(cm.controllers.teams.list[0])
          cm.controllers.eventi.setCurrent(evento);
          break;
        }
      }

      if (!found) {
        navigate('/error', {
          state: {
            error: 'Nessun evento disponibile'
          }
        })
      }
    };
    load();
    reaction(
      () => cm.controllers.eventi.current,
      async (current, reaction) => {
        r.push(reaction);
        if (current) {
          await cm.controllers.tappe.fetch();
          if (cm.controllers.tappe.list.length > 0 ) {
            const tappa = cm.controllers.tappe.list
              .slice()
              .filter(item => moment().isSame(moment(item.data), 'day'))
              console.dir(tappa)
            cm.controllers.tappe.setCurrent(tappa[0]);
          }
          await cm.controllers.segnalazioni.fetch();
        }
      }, {
        fireImmediately: true
      }
    );
    reaction(
      () => cm.controllers.segnalazioni.list,
      async (list, reaction) =>{
        r.push(reaction);
        if (cm.controllers.teams.current && cm.controllers.teams.current.assegnatoA) {
          const segn = list.filter(s => s.id === cm.controllers.teams.current.assegnatoA)[0];
          if (segn) {
            cm.controllers.segnalazioni.setCurrent(segn);
          }
        }
      },
      {fireImmediately: true}
    )
    reaction(
      () => cm.controllers.teams.current,
      (current, reaction) => {
        r.push(reaction);
        if (current && cm.controllers.segnalazioni.list.length > 0) {
          const assegnata = cm.controllers.segnalazioni.list
            .filter(item => item.id === current.assegnatoA)[0];
         
          cm.controllers.segnalazioni.setCurrent(assegnata);
        
        }
      },
      {fireImmediately: true}
    )
    return () => r.forEach(reac => reac.dispose())
  }, []);

  return <Router style={{height: '100%'}}>
    <Dashboard path="/*" />
  </Router>

  // return false
    // ? <LoadingPage text="Caricamento Informazioni" />
  // <Router style={{height: '100%'}}>
  //   // <Dashboard path="/" />
  //   {/* // <SelectType path="/nuova-segnalazione" />
  //   // <RedForm path="/nuova-segnalazione/rosso" />
  //   // <YellowForm path="/nuova-segnalazione/giallo" />
  //   // <BlueForm path="/nuova-segnalazione/blu" />
  //   // <WhiteForm path="/nuova-segnalazione/bianco" />
  //   // <Success path="/nuova-segnalazione/success" />
  //   // <ChatPage path="/chat" />
  //   // <InCorso path="/segnalazioni" />
  //   // <Risolte path="/segnalazioni-risolte"/> */}
  //  </Router>
};

export default DashboardPages;