import React, { useMemo } from 'react';
import styled from 'styled-components';
import { FireOutlined, WarningOutlined, CarOutlined, RestOutlined } from '@ant-design/icons';


const colors = {
  rosso: {
    background: '#DB3A34',
    color: '#FFF',
    icon: <FireOutlined style={{fontSize: '20px'}} />
  },
  giallo: {
    background: '#FFC857',
    color: '#333',
    icon: <WarningOutlined style={{fontSize: '20px'}} />
  },
  blu: {
    background: '#3DA5D9',
    color: '#FFF',
    icon: <CarOutlined style={{fontSize: '20px'}} />
  },
  bianco: {
    background: '#F0F0F0',
    color: '#333',
    icon: <RestOutlined style={{fontSize: '20px'}} />
  }
}


const Marker = styled.div`
  background: ${props => props.codice ? colors[props.codice].background : 'white'};
  display: flex;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  transform: translateY(-44px) translate(-50%);
  color: ${props => props.codice ? colors[props.codice].color : '#333'};
  &:after {
    content: '';
    position: absolute;
    width: 100%; 
    height: 26px;
    bottom: -14px;
    background: ${props => props.codice ? colors[props.codice].background : 'white'};
    z-index: -1;
    clip-path: polygon(0 0, 50% 100%, 100% 0);
  }
`;



const MarkerSegnalazioni = ({codice, id, onClick}) => {
  const Icon = useMemo(() => colors[codice].icon, [codice]);
  return <Marker codice={codice} onClick={onClick}>
      {Icon}
    </Marker>
}

export default MarkerSegnalazioni;