import _ from 'lodash';
import Controller from '../utils/Controller';


class API extends Controller {
  constructor(options, manager) {
    super(options, manager);
    this.hooks = {
      get: {
        pre: [],
        after: []
      },
      post: {
        pre: [],
        after: []
      },
      put: {
        pre: [],
        after: []
      },
      del: {
        pre: [],
        after: []
      }
    };
  }

  addHook(method, hook, pre = true) {
    if (method === 'all') {
      _.keys(this.hooks).forEach(key => {
        this.hooks[key][pre ? 'pre' : 'after'].push(hook);
      })
    } else {
      this.hooks[method][pre ? 'pre' : 'after'].push(hook)
    }
  }

  logStart(method, path, options) {
    console.groupCollapsed(`${method.toUpperCase()} ${path}`)
    console.log('Headers', options.headers);
    console.log('Body', options.body);
  }

  logFinish(result) {
    console.log('Result', result);
    console.groupEnd();
  }

  async applyPreHooks (method, path, opts) {
    let res = {path, opts};
    for(let i = 0; i < this.hooks[method].pre.length; i++) {
      res = await this.hooks[method].pre[i](res.path, res.opts);
    }
    return res;
  }

  async applyAfterHooks (method, result) {
    let res = result;
    for(let i = 0; i < this.hooks[method].after.length; i++) {
      res = await this.hooks[method].after[i](res);
    }
    return res;
  }
  
  async get(path, opts = {}) {
    const options = await this.applyPreHooks('get', path, opts)
    let result = await fetch(options.path, options.opts)
      .then(response => response.json());
    result = await this.applyAfterHooks('get', result);
    return result;
  }
  async post(path, opts = {}) {
    const options = await this.applyPreHooks('post', path, opts);
    options.opts.method = "POST";
    let result = await fetch(options.path, options.opts)
      .then(response => response.json());
    result = await this.applyAfterHooks('post', result);
    return result;
  }
  async put(path, opts = {})  {
    const options = await this.applyPreHooks('put', path, opts);
    options.opts.method = "PUT";
    let result = await fetch(options.path, options.opts)
      .then(response => response.json());
    result = await this.applyAfterHooks('put', result);
    return result;
  }
  async del(path, opts = {}) {
    const options = await this.applyPreHooks('del', path, opts);
    options.opts.method = "DELETE";
    let result = await fetch(options.path, options.opts)
      .then(response => response.json());
    result = await this.applyAfterHooks('del', result);
    return result;
  }

  async init() {
    super.init();
    this.options = {
      ...this.options,
      ...this.manager.controllers.config.get('api')
    }
    
    _.keys(this.hooks).forEach(key => {
      this.hooks[key].pre.push((path, opts) => {
        if (opts.body) opts.body = JSON.stringify(opts.body);
        return {
          path,
          opts
        }
      });
      this.hooks[key].pre.push((path, opts) => {
        return {
          path:`${this.options.url}${path}`,
          opts
        }
      })
    })
    if (this.options.dev) {
      _.keys(this.hooks).forEach(key => {
        this.hooks[key].pre.push((path, opts) => {
          this.logStart(key, path, opts)
          return {
            path,
            opts
          }
        });
        this.hooks[key].after.push((result) => {
          this.logFinish(result);
          return result
        })
      })
    }
  }


  
  get name() {
    return 'api'
  }

  get priority() {
    return 1000;
  }

 
}

export default API;