import React, { useState } from 'react';
import styled from 'styled-components';
import { Form, Layout, PageHeader, Tag, Input, Select, Button, Typography, message } from 'antd';
import CustomSelect from '../../../../components/custom-select';
import LoadingPage from '../../../../components/loading-page';
import { SEGNALAZIONI_COLORS } from '../../../../controllers/Segnalazioni';

import cm from '../../../../controllers';
import { navigate } from '@reach/router';

const {Content, Footer} = Layout;

const BlueForm = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  
  return <Layout style={{height: '100%'}}>
    <Typography.Title level={4}>Crea segnalazione <Tag
      style={{...SEGNALAZIONI_COLORS.blu, border: 0}}
    >Blu</Tag></Typography.Title>
    <Content style={{display: 'flex', flexDirection:'column', justifyContent: 'space-between'}}>
      <Form
        labelAlign="left"
        form={form}
        wrapperCol={{lg: 24}}
        labelCol={{lg: 24}}
        onFinish={async (values) => {
          try {
            if (!cm.controllers.posizione.current) {
              message.error('impossibile creare la segnalazione. Geolocalizzazione non disponibile');
              return;
            } 
            setLoading(true);
            const segnalazione = {
              codice: 'blu',
              ...values,
              coords: {
                lat: cm.controllers.posizione.current.latitude,
                lng: cm.controllers.posizione.current.longitude
              },
              tappa: cm.controllers.tappe.current.id,
              tipo: values.tipo === 'altro'
                ? `Altro: ${values.descrizione}`
                : values.tipo,
              coinvolti: [{tipologia: 'concorrente', numeroGara: values.coinvolti}]
            }
            await cm.controllers.segnalazioni.create(segnalazione);
            await navigate('/')
          } catch (e) {
            message.error('Errore durante la creazione della segnalazione')
          } finally {
            setLoading(false)
          }

        
        }}
      >
        <Form.Item label="Numero di gara concorrente" name="coinvolti" rules={[
          {required: true, message: 'Campo obbligatorio'}
        ]}>
          <Input placeholder="Inserisci il numero di gara" />
        </Form.Item>
        <Form.Item label="Tipologia segnalazione" name="tipo" rules={[
          {required: true, message: 'Campo obbligatorio'}
        ]}>
          <CustomSelect>
            <option value="meccanico">
              Problema meccanico
            </option>
            <option value="sanitario lieve">
              Problema sanitario lieve
            </option>
            <option value="ritiro">
              Ritiro (con consegna tabella di marcia)
            </option>
            <option value="altro">
              Altro
            </option>
          </CustomSelect>
        </Form.Item>
        <Form.Item
          shouldUpdate={(prev, cur) => {
            return (prev.tipo !== 'altro' && cur.tipo === 'altro') ||
              (prev.tipo === 'altro' && cur.tipo !== 'altro')
          }}
        >
        {() => (
          form.getFieldValue('tipo') === 'altro' 
            ? <Form.Item label="Descrizione" name="descrizione" labelAlign="left" labelCol={{lg:24}} wrapperCol={{lg: 24}}>
              <Input.TextArea style={{resize: 'none', height: '200px'}} />
            </Form.Item>
            : null
        )}

        </Form.Item>
        <Form.Item label="Note aggiuntive" name="note">
          <Input.TextArea placeholder="Inserisci eventuali note" />
        </Form.Item>
      </Form>
      <Button block type="primary" size="large" loading={loading} onClick={() => {
        form.submit();
      }}>Invia</Button>

    </Content>
  </Layout>
}

export default BlueForm;