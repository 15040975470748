import React from 'react';
import styled from 'styled-components';
import { Form, Select, Input } from 'antd';
import CustomSelect from '../../../../components/custom-select';

const Dinamica = ({form, rules}) => {
  return <>
    <Form.Item label="Dinamica incidente" name="dinamica" rules={rules}>
      <CustomSelect multiple >
        <option value="tamponamento">
          Tamponamento
        </option>
        <option value="urto frontale">
          Urto frontale
        </option>
        <option value="urto laterale">
          Urto laterale
        </option>
        <option value="urto frontale-laterale">
          Urto frontale-laterale
        </option>
        <option value="Investimento">
          Investimento
        </option>
        <option value="urto contro ostacolo">
          Urto contro ostacolo
        </option>
        <option value="fuoriuscita dalla sede stradale">
          Fuoriuscita dalla sede stradale 
        </option>
        <option value="incendio">
          Incendio
        </option>
      </CustomSelect>
    </Form.Item>
    <Form.Item
      style={{margin: 0}}
      shouldUpdate={(prev, cur) => {
        return ((prev.dinamica || []).indexOf('urto contro ostacolo') !== (cur.dinamica || []).indexOf('urto contro ostacolo'));
      }}
    >
      {
        () => {
          return (form.getFieldValue('dinamica') || []).indexOf('urto contro ostacolo') !== -1
            ? <Form.Item name="ostacolo" label="Descrivi l'ostacolo">
              <Input />
            </Form.Item>
            : null
        }
      }
    </Form.Item>
  </>
}

export default Dinamica;