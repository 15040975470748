import Controller from '../utils/Controller';
import { decorate, computed, action, observable, reaction } from 'mobx';
import { notification } from 'antd';

class Tappe extends Controller {
  constructor(options, manager) {
    super(options, manager);
    this.fetched = false;
    this.list = [];
    this.current= null
  }
 
  get map() {
    return this.list
      .reduce((agg, curr) => {
        agg[curr.id] = curr;
        return agg;
      }, {});
  }

  async init() {
    super.init();
    reaction(
      () => this.manager.controllers.eventi.current,
      () => {
        this.list = [];
        this.fetched = false;
        this.current = null;
      }
    );
    this.manager.controllers.connection.addEventListener('reconnect', this.onReconnection.bind(this));
    this.manager.controllers.websocket.addEventListener('tappa_updated', this.replaceObj.bind(this));
  }

  async onReconnection() {
    if (this.fetched) {
      await this.fetch();
    } else {
      if (this.list.length > 0) {
        for (let i = 0; i < this.list.length; i++) {
          await this.get(this.list[i].id);
        }
      }
    }
  }

  get name() {
    return 'tappe'
  }

  get priority() {
    return 1001;
  }

  async get(id, nocache = null) {
    if (!nocache && this.map[id]) {
      return this.map[id];
    } else {
      const currentEvent = this.manager.controllers.eventi.current;
      if (currentEvent) {
        const result = await this.manager.controllers.api.get(`/eventi/${currentEvent.id}/tappe/${id}`);
        this.list = [...this.list, result.Item];
        return result.Item;
      }
    }
  }

  async delete(id) {
    const currentEvent = this.manager.controllers.eventi.current;
    if (currentEvent) {
      const result = await this.manager.controllers.api.del(`/eventi/${currentEvent.id}/tappe/${id}`) 
      this.list = this.list.filter(item => item.id !== result.Item.id);
    }
  }

  async fetch() {
    const currentEvent = this.manager.controllers.eventi.current;
    if (currentEvent) {
      const result = await this.manager.controllers.api.get(`/eventi/${currentEvent.id}/tappe`) 
      this.list = result.Items;
      this.fetched = true;
    }
  }

  async create(values) {
    const currentEvent = this.manager.controllers.eventi.current;
    if (currentEvent) {
      const result = await this.manager.controllers.api.post(`/eventi/${currentEvent.id}/tappe`, {
        body: values
      });
      if (result) {
        this.list = [...this.list, result.Item]
      }
    }
  }

  async reload() {
    if (this.fetched) {
      await this.fetch();
    } else {
      for (let i = 0; i < this.list.length; i++) {
        await this.get(this.list[i].id, true)
      }
    }
  }

  async getGeocoding(id) { 
    const currentEvent = this.manager.controllers.eventi.current;
    if (currentEvent) {
      const result = await this.manager.controllers.api.get(`/eventi/${currentEvent.id}/tappe/${id}/geocoding`);
      return result.Item;      
    }
  }


  find(id) {
    return this.list.findIndex(tappa => tappa.id === id);
  }

  replace(id, valore) {
    const temp = [...this.list];
    console.dir(id);
    const idx = this.find(id);
    if (idx !== -1) {
      temp[idx] = valore;
      this.list = temp;
      console.dir(this.current);
      if (this.current && this.current.id === id) {
        notification.info({
          message: 'La tappa corrente è stata modificata'
        })
        this.current = this.list[idx];
      }
    }
  }

  replaceObj(obj) {
    if (obj) {
      this.replace(obj.id, obj);
    }
    // this.replace(obj.id, obj);
  }

  async createSector(id, settore) {
    const currentEvent = this.manager.controllers.eventi.current;
    if (currentEvent) {
      const tappa = {...await this.get(id)};
      delete tappa.id;
      
      if (!tappa.settori) tappa.settori = [];

      tappa.settori.push(settore);

      const result = await this.manager.controllers.api.put(`/eventi/${currentEvent.id}/tappe/${id}`, {
        body: tappa
      });
      this.replace(id, result.Item);
    }
  }

  async deleteSector(id, indice) {
    const currentEvent = this.manager.controllers.eventi.current;
    if (currentEvent) {
      const tappa = {...await this.get(id)};
      delete tappa.id;
      if (!tappa.settori) tappa.settori = [];

      tappa.settori.splice(indice, 1);
      const result = await this.manager.controllers.api.put(`/eventi/${currentEvent.id}/tappe/${id}`, {
        body: tappa
      });
      this.replace(id, result.Item);
    }
  }
  
  setCurrent(current) {
    this.current = current;
  }
  
}

export default decorate(Tappe, {
  list: observable,
  current: observable,
  map:computed,
  fetch: action,
  create: action,
  delete: action,
  createSector: action,
  setCurrent: action
});