import React from 'react';
import styled from 'styled-components';
import Loader from '../loader';
import { LoadingOutlined } from '@ant-design/icons';

const Container = styled.div`
  width: 100%;
  height: 100%;
  position:absolute;
  z-index: 9998;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #133b6b;
  h2 {
    margin: 0;
    color: white;
    padding: 10px;
  }
  img {
    width: 78px;
    height: 78px;
  }
`;

const BottomTitle = styled.h1`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: #fff;
  z-index: 9999;
  text-align: center;
`;

const LoadingPage = ({text}) => {
  return <><Container>
    <img src="/logo192.png" />
    <h2><LoadingOutlined /> </h2>
    {text ? <h2>{text}</h2> : null}
  </Container>
  <BottomTitle>Gestione Team Assistenza</BottomTitle>
  </>
}

export default LoadingPage;
