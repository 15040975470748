import Auth from './Auth';
import Config from './Config';
import API from './Api';
import config from '../config';
import CTS from './CTS';
import ControllerManager from '../utils/ControllerManager';
import Eventi from './Eventi';
import Tappe from './Tappe';
import Teams from './Teams';
import Segnalazioni from './Segnalazioni';
import Websocket from './Websocket';
import Position from './Position';
import Connection from './Connection';
import Chat from './Chat';
import DB from './DB';
import { observable } from 'mobx';

const controllerManager =  new ControllerManager([{
  Class: Config,
  options: {
    config
  }
}, {
  Class: Auth
}, {
  Class: API
}, {
  Class: CTS
}, {
  Class: Eventi
}, {
  Class: Tappe
}, {
  Class: Teams
}, {
  Class: Segnalazioni
}, {
  Class: Websocket
}, {
  Class: Position
},{
  Class: Connection
}, {
  Class: Chat
}, {
  Class: DB
}]);

const reloadAll = async () => {
  const controllerKeys = Object.keys(controllerManager.controllers);
  for (let i = 0; i < controllerKeys.length; i++) {
    const key = controllerKeys[i];
    if (controllerManager.controllers[key].reload) {
      await controllerManager.controllers[key].reload();
    }
  }
}

let online = navigator.onLine

document.addEventListener('offline', async () => {
  online = false;
})

document.addEventListener('online', async () => {
  if (!online) {
    await reloadAll();
  }
  online = true;
})

const updatePosition = () => {
  setTimeout(() => {
    if (
      controllerManager.controllers.websocket.isOpen() &&
      controllerManager.controllers.posizione.current &&
      controllerManager.controllers.eventi.current.id
    ) {
     controllerManager.controllers.websocket.send(JSON.stringify({
        action: 'position_update',
        body: {
          coords: controllerManager.controllers.posizione.current,
          event: controllerManager.controllers.eventi.current.id
        }
      }))
    } else {
      updatePosition();
    }
  }, 2000)
}

document.addEventListener('visibilitychange', async function() {
  if (document.visibilityState == 'visible') {
    await reloadAll();
    updatePosition();
  }
});

controllerManager.controllers.api.addHook('all', async (path, opts) => {
  if (!opts.headers) opts.headers = {};

  const session = await controllerManager.controllers.auth.getCurrentSession();

  opts.headers.Authorization = `Bearer ${session.accessToken.jwtToken}`;

  return {path, opts};
});

export const version = observable.box()
const setVersion = (vers) => {
  version.set(vers);
  if (window.localStorage) {
    window.localStorage.setItem('v', vers);
  }
};

const getVersion = () => {
  if (window.localStorage) {
    const v = window.localStorage.getItem('v');
    version.set(v);
    return v;
  } else {
    return version.get();
  }
}

const tempV = getVersion();
if (tempV) {
  version.set(tempV);
} else {
  fetch(`https://app.gta.1000miglia.it/v.txt`, {
    mode: 'no-cors'
  })
    .then(res => res.text())
    .then(res => {
      const v = getVersion();
      if (!v) {
        setVersion(res);
      } else {
        let current = v.split('.');
        let newV = res.split('.');
        for (let i = 0; i < newV.length; i++) {
          if (parseInt(newV[i]) > parseInt(current[i])) {
            setVersion(res)
            downloadNewVersion()
            break;
          }
        }
      }
    })
    .catch(console.error)
}

const downloadNewVersion = async () => {
  navigator.serviceWorker.getRegistrations().then(async function(registrations) {
    for(let registration of registrations) {
      await registration.unregister()
    }  
    window.location = '/'
  }).catch(function(err) {
      console.log('Service Worker registration failed: ', err);
    });
};



setInterval(() => {
  fetch(`https://app.gta.1000miglia.it/v.txt`, {
    mode: 'no-cors'
  })
    .then(res => res.text())
    .then(res => {
      const v = getVersion();
      if (!v) {
        setVersion(res);
      } else {
        let current = v.split('.');
        let newV = res.split('.');
        for (let i = 0; i < newV.length; i++) {
          if (parseInt(newV[i]) > parseInt(current[i])) {
            setVersion(res)
            downloadNewVersion()
            break;
          }
        }
      }
    })
    .catch(console.error)
}, 60 * 1000);

export default controllerManager;