import React from 'react';
import styled from 'styled-components';
import { PageHeader } from 'antd';


const CustomPageHeader = styled(PageHeader)`
  background: #1c1c1c;
  .ant-page-header-heading-title {
    color: #fff;
  }
  .anticon  {
    color: #fff;
    svg {
      fill: #fff !important;
    }
  }
`;
 
export default CustomPageHeader;