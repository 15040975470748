import React from 'react';
import { Storage } from 'aws-amplify';

const withS3Src = (Component) => (props) => {
  const [url, setUrl] = React.useState(null);

  const propName = React.useMemo(() => {
    return props.propName || 'src'
  }, [props.propName]);

  const value = props[propName];
  
  React.useEffect(() => {
    const retrieveSignedUrl = async (url) => {
      const signedUrl = await Storage.get(url);
      setUrl(signedUrl);
    }
    retrieveSignedUrl(value)
  }, [propName, value]);

  const newProps = React.useMemo(() => {
    return {...props, [propName]: url}
  }, [url, propName]);

  return <Component {...newProps} />
}

export default withS3Src;