import Controller from '../utils/Controller';

class Config extends Controller {
  constructor(options, manager) {
    super(options, manager);
  }

  get(key) {
    return this.config[key];
  }

  set(key, value) {
    this.config[key] = value;
  }

  get name() {
    return 'config'
  }

  get priority() {
    return 2;
  }

  init() {
    super.init();
    this.config = this.options.config;
  }

}

export default Config;