import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';

import 'antd/dist/antd.less';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";


import config from './config';

Sentry.init({
  dsn: "https://57f00a71ec964b83a57145741fffd092@o426482.ingest.sentry.io/5454460",
  integrations: [
    new Integrations.BrowserTracing(),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

Amplify.configure(config.Amplify);

ReactDOM.render(
  <App />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
