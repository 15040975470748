import React from 'react';
import { Redirect, Router } from '@reach/router'
import styled from 'styled-components';
import LoginPage from './login';
import RequestPasswordReset from './request-password-reset';
import ResetPassword from './reset-password';
import Challenge from './challenge';

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: #133B6B;
  padding: 50px;
`;


const Logo = styled.img`
  display: inline-block;
  padding: 5px;
  background:  white;
  border-radius:5px;
  max-width: 100%;
  @media
`


const AuthPages = () => {
  return <Container>
    <Logo src='/logo_full.png' />
    <Router>
      <Redirect from="/" to="login"/>
      <LoginPage path="/login" />
      <RequestPasswordReset path="/request-password-reset" />
      <ResetPassword path="/reset-password" />
      <Challenge path="/challenge/*" />
    </Router>
  </Container>
  
  
};

export default AuthPages;