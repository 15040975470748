import React from 'react' 
import styled from 'styled-components';
import {Form, Select} from 'antd';
import CustomSelect from '../../../../components/custom-select';

const RichiestaIntervento = () => {
  return <Form.Item  name="richiesta-intervento" label="Richiesta intervento">
    <CustomSelect multiple>
      <option value="112-118">
        Intervento 112-118
      </option>
      <option value="medical-car">
        Intervento Medical Car
      </option>
      <option value="support-car">
        Intervento Support Car
      </option>
      <option value="carro-attrezzi-aci">
        Carro Attrezzi Aci global
      </option>
    </CustomSelect>
  </Form.Item>
}

export default RichiestaIntervento;