import _ from 'lodash';
import Controller from '../utils/Controller';


class Websocket extends Controller {
  constructor(options, manager) {
    super(options, manager);
    this.ws = null;
    this.listeners = {};
  }

  
  get name() {
    return 'websocket'
  }

  get priority() {
    return 1000;
  }

  addEventListener(event, callback) {
    if (!this.listeners[event]) this.listeners[event] = [];
    this.listeners[event].push(callback);
  }

  removeEventListener(event, callback) {
    if (!this.listeners[event]) return;
    this.listeners[event] = this.listeners[event].filter(listener => listener !== callback);
  }

  async init() {
    this.config = this.manager.controllers.config.get('websocket');
  }

  async reload() {
    if (!this.ws.OPEN) {
      await this.connect();
    }
  }

  async connect() {
    const session = await this.manager.controllers.auth.getCurrentSession();    
    console.log('connecting websocket');
    const connectionStr = `${this.config.url}?Auth=${session.accessToken.jwtToken}`;
    const websocket = new WebSocket(connectionStr)
    websocket.onopen = () => {
      this.ws = websocket;
      console.log('connected to websocket v2');
    }
    websocket.onerror = function(e) {
      console.error(e)
    }
    websocket.onclose = () => {
      this.ws = null;
      setTimeout(this.connect.bind(this), 500);
    }
    websocket.onmessage = (event) => {
      const message = JSON.parse(event.data);
      console.log('received message', message);
      if (!this.listeners[message.action]) return;
      this.listeners[message.action].forEach(fn => {
        fn(message.body, websocket)
      });

    }
    this.ws = websocket;

  }

  send(data) {
    if (this.ws && this.ws.OPEN) {
      console.dir(data);
      this.ws.send(data);
    }
  }

  isOpen() {
    return this.ws && this.ws.OPEN
  }

 
}

export default Websocket;